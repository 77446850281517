import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
const HDWalletProvider = require('@truffle/hdwallet-provider');

import detectEthereumProvider from '@metamask/detect-provider';
import {
    IconButton,
    Tooltip,
    Spinner,
    Alert,
    AlertIcon,
    CloseButton,
    useToast,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import BalanceModal from './BalanceModal.js';
import DepositModal from './DepositModal.js';
import ReactTooltip from 'react-tooltip';
const config = require('./configs/tusd_abi.js');
const PORTFOLIO_ABI = config.portfolioAbi;

export default function Market() {
    const [perpetuals, setPerpetuals] = useState([]);
    const [longShort, setLongShort] = useState([]);
    const [purchases, setPurchases] = useState([]);
    const [web3, setWeb3] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const [contract, setContract] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(50);
    const [balance, setBalance] = useState(0);
    const [amountInvested, setAmountInvested] = useState(0);
    const [currentValuation, setCurrentValuation] = useState(0);
    const toast = useToast();
    const [portfolioAddress, setPortfolioAddress] = useState(null);
    const [privateKeys, setPrivateKeys] = useState(null);
    const [providerOrUrl, setProviderOrUrl] = useState(null);
    const [sidechainWeb3, setSidechainWeb3] = useState(null);
    const [sidechainAccounts, setSidechainAccounts] = useState([]);

    const serverURL = process.env.REACT_APP_SERVER_URL;

    useEffect(() => {
        const fetchSecrets = async () => {
            try {
                const response = await fetch(`${serverURL}/api/secrets`);
                const data = await response.json();

                setPortfolioAddress(data.portfolioAddress);
                setProviderOrUrl(data.providerOrUrl);

                let formattedPrivateKeys = data.privateKeys;

                if (typeof formattedPrivateKeys === 'string') {
                    try {
                        formattedPrivateKeys = JSON.parse(formattedPrivateKeys);
                    } catch (e) {
                        formattedPrivateKeys = [formattedPrivateKeys];
                    }
                }

                formattedPrivateKeys = formattedPrivateKeys.map((key) =>
                    key.startsWith('0x') ? key : `0x${key}`
                );
                setPrivateKeys(formattedPrivateKeys);
            } catch (error) {
                console.error('Error fetching secrets:', error);
            }
        };

        fetchSecrets();
    }, []);

    useEffect(() => {
        if (!privateKeys || !providerOrUrl || privateKeys.length === 0) {
            return;
        }
        let provider;
        async function fetchWeb3Data() {
            try {
                const token = localStorage.getItem('jwtToken');
                if (!token) {
                    window.location.href = '/sign';
                    return;
                }
                provider = await detectEthereumProvider();
                if (!provider) {
                    console.error('No Ethereum provider detected.');
                    return;
                }

                await provider.request({ method: 'eth_requestAccounts' });

                const web3Instance = new Web3(provider);
                setWeb3(web3Instance);
                const accounts = await web3Instance.eth.getAccounts();
                setAccounts(accounts);

                const response = await fetch(
                    `${serverURL}/api/init-perpetual`,
                    {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ privateKeys, providerOrUrl }),
                    }
                );

                if (!response.ok) {
                    throw new Error('Failed to fetch Web3 data');
                }

                const data = await response.json();
                setSidechainAccounts(data.accounts);

                const sidechainWeb3Instance = new Web3(providerOrUrl);
                setSidechainWeb3(sidechainWeb3Instance);

                const contractInstance = new sidechainWeb3Instance.eth.Contract(
                    PORTFOLIO_ABI,
                    portfolioAddress
                );

                setContract(contractInstance);

                if (contractInstance && accounts.length > 0) {
                    setBalance(data.balance);
                    setPurchases(data.purchases);
                    setPerpetuals(data.perpetualsWithStats);
                }
            } catch (error) {
                console.error('Error fetching Web3 data:', error);
            }
        }

        fetchWeb3Data();
    }, [privateKeys, providerOrUrl]);

    // const getAllPerpetuals = async (contractInstance) => {
    //     try {
    //         const perpetuals = await contractInstance.methods
    //             .getAllPerpetualsWithStats()
    //             .call();
    //         setPerpetuals(perpetuals);
    //         console.log(perpetuals);
    //     } catch (error) {
    //         console.error('Error getting all perpetuals:', error);
    //     }
    // };

    // const getPurchasesByBuyer = async (contractInstance, buyer) => {
    //     try {
    //         const purchases = await contractInstance.methods
    //             .getPurchasesByBuyer(buyer)
    //             .call();
    //         setPurchases(purchases);
    //     } catch (error) {
    //         console.error('Error getting purchases by buyer:', error);
    //         console.log('Contract Instance:', contractInstance);
    //     }
    // };

    useEffect(() => {
        if (contract && accounts.length > 0) {
            getUserRecords();
        }
    }, [contract, accounts]);

    const getUserRecords = async () => {
        if (!contract || accounts.length === 0) {
            console.error('Contract not initialized or no accounts available.');
            return;
        }
        try {
            const userAddy = accounts[0];
            const userRecords = await contract.methods
                .searchRecordsByAddress(userAddy, 0)
                .call();
            const totalCurrentValuation = userRecords.reduce(
                (total, record) => {
                    const valuationInWei = BigInt(
                        record.currentValuation || '0'
                    );
                    return total + valuationInWei;
                },
                BigInt(0)
            ); // Initialize total as BigInt
            const formatTotalCurrentValuation = parseFloat(
                Web3.utils.fromWei(totalCurrentValuation.toString(), 'ether')
            ).toFixed(2);
            setCurrentValuation(formatTotalCurrentValuation);
            setAmountInvested(userRecords.amountInvested);
        } catch (error) {
            console.error('Error searching records:', error);
        }
    };

    const navigate = useNavigate();

    const handleTradeClick = (perpetual, index) => {
        console.log(`index and perp index ${index}`, perpetual);
        navigate('/trade', { state: { perpetual, index } });
    };

    // const getBalance = async (contractInstance, account) => {
    //     try {
    //         const balance = await contractInstance.methods
    //             .getNetBalance(account)
    //             .call();
    //         setBalance(
    //             parseFloat(
    //                 Web3.utils.fromWei(balance.toString(), 'ether')
    //             ).toFixed(2)
    //         );
    //         const metaBalance = contractInstance.methods.getBalance(account);
    //     } catch (error) {
    //         console.error('Error getting balance:', error);
    //     }
    // };

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = perpetuals.slice(
        indexOfFirstRecord,
        indexOfLastRecord
    );

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="w-full lg:w-[1290px] h-auto lg:h-[734px] relative mt-8 lg:mt-[63px] ml-4 lg:ml-[111px]">
            <div className="flex w-full lg:w-[230px] h-auto lg:h-[656px] flex-col gap-4 lg:gap-[18px] top-0 left-0">
                <BalanceModal />
                <DepositModal />
            </div>

            {/* Main Content */}
            <div className="flex flex-col gap-2 items-center w-full lg:w-[1040px] h-auto lg:h-[734px] p-2 bg-white rounded-lg border border-blue-200 absolute top-0 left-[250px] ">
                <div className="flex flex-col gap-2 bg-gray-100 rounded-md p-4 w-full">
                    <div className="flex gap-2 items-center">
                        <div className="w-4 h-3 bg-cover bg-no-repeat market-icon"></div>
                        <span className="text-2xl font-bold text-blue-800">
                            Market
                        </span>
                    </div>
                    <span className="text-xs text-gray-500">
                        Perpetual futures available for trade.
                    </span>
                </div>

                {/* Trade Table */}
                <table className="w-full table-auto border-separate border-spacing-2">
                    <thead className="bg-gray-200">
                        <tr className="bg-gray-200">
                            <th className="text-left py-2 px-4 text-sm font-semibold text-gray-600">
                                Asset&nbsp;
                                <button
                                    data-tip="Name of Asset"
                                    id="myButton"
                                    className="tooltip-icon"
                                ></button>
                                <ReactTooltip anchorId="myButton" />
                            </th>
                            <th className="text-left py-2 px-4 text-sm font-semibold text-gray-600">
                                Contract&nbsp;
                                <button
                                    data-tip="Contract Address of Perpetual"
                                    id="myButton"
                                    className="tooltip-icon"
                                ></button>
                                <ReactTooltip anchorId="myButton" />
                            </th>
                            <th className="text-left py-2 px-4 text-sm font-semibold text-gray-600">
                                Name&nbsp;
                                <button
                                    data-tip="Name of Perpetual"
                                    id="myButton"
                                    className="tooltip-icon"
                                ></button>
                                <ReactTooltip anchorId="myButton" />
                            </th>
                            <th className="text-left py-2 px-4 text-sm font-semibold text-gray-600">
                                Price (tUSD)&nbsp;
                                <button
                                    data-tip="Current Price of Perpetual"
                                    id="myButton"
                                    className="tooltip-icon"
                                ></button>
                                <ReactTooltip anchorId="myButton" />
                            </th>
                            <th className="text-left py-2 px-4 text-sm font-semibold text-gray-600">
                                Longs vs Shorts&nbsp;
                                <button
                                    data-tip="Percentage of Longs vs Shorts Positions"
                                    id="myButton"
                                    className="tooltip-icon"
                                ></button>
                                <ReactTooltip anchorId="myButton" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentRecords.map((record, index) => {
                            if (
                                !record ||
                                typeof record !== 'object' ||
                                record.status !== true
                            ) {
                                return null;
                            }

                            return (
                                <tr
                                    key={record.perpetualAddress || index}
                                    className="border-b"
                                >
                                    <td className="flex items-center gap-2 py-2 px-4">
                                        <span className="text-sm text-blue-800">
                                            {record.asset} {/* Asset */}
                                        </span>
                                    </td>
                                    <td className="py-2 px-4 text-sm text-blue-800">
                                        {record.perpetualAddress?.length > 10
                                            ? `${record.perpetualAddress.slice(
                                                  0,
                                                  6
                                              )}...${record.perpetualAddress.slice(
                                                  -4
                                              )}`
                                            : 'N/A'}
                                        {/* Contract */}
                                    </td>
                                    <td className="py-2 px-4 text-sm text-blue-800">
                                        {record.name || 'N/A'} {/* Name */}
                                    </td>
                                    <td className="py-2 px-4 text-sm text-blue-800">
                                        ${record.price}
                                    </td>
                                    <td className="flex items-center justify-center py-2 px-4 gap-2">
                                        <span className="text-green-600 font-bold text-sm">
                                            {record.percentageHigher || '0'}%
                                            {/* Percentage Higher */}
                                        </span>
                                        <span className="text-gray-600">
                                            vs
                                        </span>
                                        <span className="text-red-600 font-bold text-sm">
                                            {record.percentageLower || '0'}%
                                            {/* Percentage Lower */}
                                        </span>
                                    </td>
                                    <td className="py-2 px-4">
                                        <button
                                            className="bg-blue-800 text-white text-sm font-semibold py-1 px-4 rounded-lg"
                                            onClick={() =>
                                                handleTradeClick(record, index)
                                            }
                                        >
                                            Trade
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
