import { ArrowForwardIcon, SettingsIcon } from '@chakra-ui/icons';
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Button,
    Center,
    Container,
    Divider,
    Flex,
    HStack,
    Heading,
    Image,
    Input,
    Link,
    ListItem,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverTrigger,
    Stack,
    Text,
    UnorderedList,
    VStack,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import axios from 'axios';
import React, { useEffect, useRef, useState, useReducer } from 'react';
import { FaEthereum } from 'react-icons/fa';
import { GrUserAdmin } from 'react-icons/gr';
import { RxExit } from 'react-icons/rx';
import {
    Route,
    Link as RouteLink,
    BrowserRouter as Router,
    Routes,
} from 'react-router-dom';
import SelectWalletModal from './Modal.js';
import { connectors } from './connectors.js';
import Admin from './Admin.js';
import Sign from './Sign.js';
import Portfolio from './Portfolio.js';
import Transactions from './Transactions.js';
import Perpetuals from './Perpetuals.js';
import Trade from './Trade.js';
import OpenDetail from './OpenDetail.js';
import Timer from './Timer.js';
import './styles.css';
import { Mail, X, ExternalLink } from 'lucide-react';
import { termsOfService } from './terms-config.js';

export default function Home() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isOpenTUSD, setIsOpenTUSD] = useState(false);
    const [isOpenUSDC, setIsOpenUSDC] = useState(false);
    const { library, chainId, account, activate, deactivate, active } =
        useWeb3React();
    const [signature, setSignature] = useState('');
    const [network, setNetwork] = useState(undefined);
    const [message, setMessage] = useState('');
    const [verified, setVerified] = useState();
    const [jwtToken, setJwtToken] = useState();
    const [amount, setAmount] = useState(0);
    const [pair, setPair] = useState('');
    const [recipient, setRecipient] = useState('');
    const [admins, setAdmins] = useState('');

    var [_transactionHash, _setTransactionHash] = useState('');
    const {
        isOpen: isOpenAbout,
        onOpen: onOpenAbout,
        onClose: onCloseAbout,
    } = useDisclosure();
    const {
        isOpen: isOpenSupport,
        onOpen: onOpenSupport,
        onClose: onCloseSupport,
    } = useDisclosure();

    const baseURL = process.env.REACT_APP_SERVER_URL;
    const serverURL = process.env.REACT_APP_SERVER_URL;
    useEffect(() => {
        const fetchSecrets = async () => {
            try {
                const response = await fetch(`${serverURL}/api/secrets`);
                const data = await response.json();

                setAdmins(data.admins);
            } catch (error) {
                console.error('Error fetching secrets:', error);
            }
        };

        fetchSecrets();
    }, []);
    useEffect(() => {
        fetchJWT();
    }, []);

    useEffect(() => {
        const provider = window.localStorage.getItem('provider');
        if (provider) activate(connectors[provider]);
    }, []);

    const inputRef = useRef(null);
    const handleCopyClick = async () => {
        if (navigator.clipboard) {
            try {
                var accountString = { account };
                var accountString = accountString['account'];
                await navigator.clipboard.writeText(accountString);
                toast({
                    title: 'Success',
                    position: 'top-center',
                    description: 'Wallet address copied',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            } catch (err) {
                toast({
                    title: 'Error',
                    position: 'top-center',
                    description: 'Failed to copy wallet address',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } else {
            inputRef.current.select();
            document.execCommand('copy');
            toast({
                title: 'Success',
                position: 'top-center',
                description: 'Wallet address copied',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        }
    };
    const gradientStyle = {
        minHeight: '100vh',
        background: '#fff',
        display: 'flex',
        justifyContent: 'center',
    };

    const handlePAIR = (e) => {
        const pair = e.target.value;
        setPair(pair);
    };

    const handleInput = (e) => {
        const msg = e.target.value;
        setMessage(msg);
    };

    const handleAmount = (e) => {
        const amount = e.target.value * 1000000;
        setAmount(amount);
    };

    const handleRecipient = (e) => {
        const recipient = e.target.value;
        setRecipient(recipient);
        //console.log("Rec : "+recipient);
    };

    // const fetchTokens = () => {
    //     getTotalNumberOfTokens()
    //         .then((balance) => {
    //             //console.log("Total Number of Tokens : "+balance);
    //             totalAssets = balance;
    //             setTotalAssets(balance);
    //         })
    //         .catch((err) => {
    //             //console.log(err);
    //         });
    // };

    const fetchJWT = () => {
        const token = localStorage.getItem('jwtToken');
        if (!token) {
            //window.location.href = '/sign';
        } else {
            setJwtToken(token);
            //insertNewUser(ip, account, true);
        }
    };

    const getIpAddress = async () => {
        try {
            const response = await fetch('https://api.ipify.org');
            const data = await response.text();
            console.log('Data' + data);
            return data;
        } catch (error) {
            console.error('Failed to fetch IP: ', error);
            return null;
        }
    };
    const getWalletAddress = async (walletAddress) => {
        try {
            const response = await fetch(`${serverURL}/api/save_wallet`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ walletAddress }),
            });
            const data = await response.json();
            console.log('Server Response:', data);
        } catch (error) {
            console.error('Failed to send wallet address:', error);
        }
    };

    const refreshState = () => {
        window.localStorage.setItem('provider', undefined);
        setNetwork('');
        setMessage('');
        setSignature('');
        setVerified(undefined);
    };

    const disconnect = () => {
        const token = localStorage.getItem('jwtToken');
        if (token) {
            axios
                .get(`${baseURL}/api/session/stop`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((response) => {
                    //console.log("Session terminated");
                })
                .catch((error) => {
                    //console.error("Error fetching items!", error);
                    //console.log(JSON.stringify(error));
                });
        }
        localStorage.removeItem('jwtToken');
        refreshState();
        deactivate();
    };

    const BodyContent = () => (
        <Router>
            <Box className="main-container w-full bg-gray-100 p-4">
                <div className="h-screen header flex flex-wrap items-center justify-between bg-white p-4 ">
                    <RouteLink
                        to="/"
                        className="logo-text text-2xl font-bold text-gray-800"
                    >
                        OnlyX.io
                    </RouteLink>

                    <div className="header-buttons flex space-x-4">
                        <RouteLink
                            to="/perpetuals"
                            className="perpetuals-btn flex items-center space-x-2"
                        >
                            <div className="market-icon"></div>
                            <span className="perpetuals-text text-lg font-semibold text-gray-600">
                                Market
                            </span>
                        </RouteLink>

                        <RouteLink
                            to="/transactions"
                            className="transactions-btn flex items-center space-x-2"
                        >
                            <div className="portfolio-icon"></div>
                            <span className="transactions-text text-lg font-semibold text-gray-600">
                                Portfolio
                            </span>
                        </RouteLink>
                    </div>

                    <div className="funding-info flex items-center space-x-2 text-gray-600">
                        <div className="funding-icon"></div>
                        <div className="funding-text flex">
                            <span className="funding-interval text-sm">
                                Funding Interval:
                            </span>
                            <Timer></Timer>
                        </div>
                    </div>

                    <div className="account-section">
                        {!active ? (
                            <button
                                className="account-btn flex items-center space-x-2 px-4 py-2 bg-blue-500 text-white rounded-md"
                                onClick={onOpen}
                            >
                                <FaEthereum />
                                <span className="account-text">
                                    Connect Wallet
                                </span>
                                <ArrowForwardIcon />
                            </button>
                        ) : (
                            <Popover>
                                <PopoverTrigger>
                                    <button className="account-btn flex items-center space-x-2 px-4 py-2 bg-blue-500 text-white rounded-md z-[1000] ">
                                        <FaEthereum />
                                        <span className="account-text">
                                            {account.slice(0, 6) +
                                                '...' +
                                                account.slice(-4)}
                                        </span>
                                        <SettingsIcon />
                                    </button>
                                </PopoverTrigger>
                                <PopoverContent className="p-4 rounded-lg z-[1000] ">
                                    <PopoverArrow />
                                    <PopoverCloseButton />
                                    <PopoverBody>
                                        <Text className="account-text font-bold z-[1000] ">
                                            Wallet
                                        </Text>
                                        <div className="account-info flex items-center space-x-2 mt-2">
                                            <FaEthereum />
                                            <span>
                                                {account.slice(0, 6) +
                                                    '...' +
                                                    account.slice(-4)}
                                            </span>
                                            <Button
                                                onClick={handleCopyClick}
                                                size="xs"
                                                colorScheme="teal"
                                            >
                                                Copy
                                            </Button>
                                        </div>
                                        <Text className="mt-4 font-semibold z-[1000] ">
                                            Slippage settings
                                        </Text>
                                        <Text className="mt-2 text-gray-600 text-sm">
                                            Select the amount of tUSD slippage
                                            you would like to set.{' '}
                                            <Link href="#" color="blue">
                                                Learn More
                                            </Link>
                                        </Text>
                                        <Text className="mt-1 font-semibold text-sm">
                                            Your current slippage: 0.50%
                                        </Text>
                                        <Stack
                                            spacing={2}
                                            direction="row"
                                            className="mt-4"
                                        >
                                            <Button
                                                colorScheme="teal"
                                                size="sm"
                                            >
                                                0.5%
                                            </Button>
                                            <Button
                                                colorScheme="teal"
                                                variant="outline"
                                                size="sm"
                                            >
                                                1.0%
                                            </Button>
                                            <Button
                                                colorScheme="teal"
                                                variant="outline"
                                                size="sm"
                                            >
                                                1.5%
                                            </Button>
                                        </Stack>
                                        <Divider className="my-4" />
                                        <Button
                                            onClick={disconnect}
                                            variant="ghost"
                                            leftIcon={<RxExit />}
                                        >
                                            Disconnect Wallet
                                        </Button>
                                        {admins.includes(account) && (
                                            <Link href="/admin">
                                                <Button
                                                    leftIcon={<GrUserAdmin />}
                                                    colorScheme="red"
                                                    variant="outline"
                                                    size="sm"
                                                    className="mt-4"
                                                >
                                                    Administrative Control
                                                </Button>
                                            </Link>
                                        )}
                                        <div className="footer-links flex space-x-4 mt-4">
                                            <Link href="#">Terms</Link>
                                            <Link href="#">Privacy</Link>
                                            <Link href="#">Support</Link>
                                        </div>
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>
                        )}
                    </div>
                </div>
                <div className="content-container overflow-y-auto flex-grow p-4">
                    <Center>
                        <Flex className="mt-4 mb-4">
                            <Routes>
                                <Route exact path="/" element={<HomePage />} />
                                <Route path="/admin" element={<Admin />} />
                                <Route path="/sign" element={<Sign />} />
                                <Route
                                    path="/openDetail"
                                    element={<OpenDetail />}
                                />
                                <Route
                                    path="/perpetuals"
                                    element={<Perpetuals />}
                                />
                                <Route
                                    path="/portfolio"
                                    element={<Portfolio />}
                                />
                                <Route path="/trade" element={<Trade />} />
                                <Route
                                    path="/transactions"
                                    element={<Transactions />}
                                />
                            </Routes>
                        </Flex>
                    </Center>
                </div>
            </Box>
        </Router>
    );

    const Footer = () => {
        const { isOpen, onOpen, onClose } = useDisclosure(); // For Privacy Policy
        const {
            isOpen: isOpenTerms,
            onOpen: onOpenTerms,
            onClose: onCloseTerms,
        } = useDisclosure(); // For Terms of Service

        return (
            <>
                <Flex
                    direction={{ base: 'column', md: 'row' }}
                    justify="space-between"
                    width="80%"
                    m="0 auto"
                >
                    <Stack
                        spacing={3}
                        mb={{ base: 4, md: 0 }}
                        backgroundImage={
                            process.env.PUBLIC_URL + '/images/world.svg'
                        }
                        backgroundSize="100%"
                        backgroundPosition="center"
                    >
                        <Heading fontWeight="520">OnlyX.io</Heading>
                        <Text fontWeight="120">&copy;2024</Text>
                    </Stack>
                    <Stack spacing={3} mb={{ base: 4, md: 0 }}>
                        <Heading size="md">Company</Heading>
                        <Link onClick={onOpenAbout} href="#!">
                            About Us
                        </Link>
                        <HStack spacing={1} alignItems="center">
                            <Link
                                target="_blank"
                                href={`https://www.linkedin.com/company/onlyx/`}
                            >
                                LinkedIn
                            </Link>
                            <ExternalLink className="w-4 h-4" />
                        </HStack>
                    </Stack>

                    <Stack spacing={3} mb={{ base: 4, md: 0 }}>
                        <Heading size="md">Terms & Policies</Heading>
                        <Link onClick={onOpenTerms} href="#!">
                            Terms of Service
                        </Link>
                        <Link onClick={onOpen} href="#!">
                            Privacy Policy
                        </Link>
                    </Stack>

                    <Stack spacing={3} mb={{ base: 4, md: 0 }}>
                        <Heading size="md">Support</Heading>
                        <Link onClick={onOpenSupport} href="#!">
                            Contact & Social
                        </Link>
                    </Stack>
                </Flex>

                <PrivacyPolicyModal isOpen={isOpen} onClose={onClose} />

                <TermsOfServiceModal
                    isOpen={isOpenTerms}
                    onClose={onCloseTerms}
                />

                <AboutUsModal isOpen={isOpenAbout} onClose={onCloseAbout} />

                <SupportModal isOpen={isOpenSupport} onClose={onCloseSupport} />
            </>
        );
    };

    const PrivacyPolicyModal = ({ isOpen, onClose }) => {
        return (
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                size="4xl"
                scrollBehavior="inside"
            >
                <ModalOverlay backdropFilter="blur(10px)" />
                <ModalContent maxH="85vh">
                    <ModalHeader>
                        <Heading size="lg">Privacy Policy</Heading>
                        <Text fontSize="sm" color="gray.600" mt={2}>
                            Last updated October 22, 2024
                        </Text>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack align="stretch" spacing={6}>
                            {/* Introduction */}
                            <Box>
                                <Text mb={4}>
                                    This Privacy Policy ("Policy") describes how
                                    OnlyX and its affiliates ("OnlyX", "we",
                                    "us" or "our") collect, use, safeguard and
                                    disclose information and your rights and
                                    choices regarding this information.
                                </Text>
                                <Text mb={4}>
                                    Please read this Policy carefully. If you
                                    have any questions, please contact us using
                                    the details in the "Contact Us" section of
                                    this Policy.
                                </Text>
                            </Box>

                            {/* Applicability */}
                            <Box>
                                <Heading size="md" mb={3}>
                                    Applicability of This Policy
                                </Heading>
                                <Text>
                                    This Policy applies to personal information
                                    collected in connection with the Services
                                    (as defined in our Terms of Use). If you do
                                    not agree with the terms of this Policy, do
                                    not access or use the Services.
                                </Text>
                            </Box>

                            {/* What We Collect */}
                            <Box>
                                <Heading size="md" mb={3}>
                                    What We Collect
                                </Heading>
                                <Text mb={2}>
                                    When you interact with the Services, we
                                    collect the following information:
                                </Text>
                                <UnorderedList pl={4} spacing={2} mb={4}>
                                    <ListItem>Wallet address</ListItem>
                                    <ListItem>
                                        Browser metadata, including IP address
                                        and browser type
                                    </ListItem>
                                    <ListItem>
                                        Transaction data related to your use of
                                        our platform
                                    </ListItem>
                                </UnorderedList>
                                <Text fontWeight="medium">
                                    We do not collect names, email addresses, or
                                    phone numbers.
                                </Text>
                            </Box>

                            {/* How We Collect Information */}
                            <Box>
                                <Heading size="md" mb={3}>
                                    How We Collect Information
                                </Heading>
                                <Text mb={2}>We collect information:</Text>
                                <UnorderedList pl={4} spacing={2}>
                                    <ListItem>
                                        Directly from you when you use our
                                        Services
                                    </ListItem>
                                    <ListItem>
                                        Automatically through your use of the
                                        Services, including through cookies
                                    </ListItem>
                                </UnorderedList>
                            </Box>

                            {/* Online Tracking */}
                            <Box>
                                <Heading size="md" mb={3}>
                                    Online Tracking
                                </Heading>
                                <Text mb={4}>
                                    We use various technologies to collect and
                                    store information when you visit our
                                    Services, including cookies, pixel tags,
                                    local storage, and device identifiers.
                                </Text>

                                <Text fontWeight="medium" mb={2}>
                                    Cookies
                                </Text>
                                <Text mb={4}>
                                    Cookies are small text files stored on your
                                    browser or device. We use cookies to
                                    maintain sessions. These cookies are
                                    terminated when you disconnect your wallet.
                                </Text>

                                <Text fontWeight="medium" mb={2}>
                                    Pixel Tags
                                </Text>
                                <Text mb={4}>
                                    We use pixel tags (also known as web beacons
                                    and clear GIFs) on our Services to, among
                                    other things, track the actions of users of
                                    our Services, measure the success of our
                                    marketing campaigns, and compile statistics
                                    about usage of our Services.
                                </Text>

                                <Text fontWeight="medium" mb={2}>
                                    Local Storage
                                </Text>
                                <Text mb={4}>
                                    We use local storage, such as HTML5 Storage,
                                    to enhance the user experience by, for
                                    example, storing your preferences.
                                </Text>

                                <Text fontWeight="medium" mb={2}>
                                    Device Identifiers
                                </Text>
                                <Text mb={4}>
                                    We use device identifiers to track, analyze,
                                    and improve the performance of the Services
                                    and our ads.
                                </Text>
                            </Box>

                            {/* Blockchain Transactions */}
                            <Box>
                                <Heading size="md" mb={3}>
                                    Blockchain Transactions
                                </Heading>
                                <Text mb={4}>
                                    Due to the inherent transparency of
                                    blockchain technology, transactions
                                    conducted on the blockchain are public.
                                    While we do not link blockchain addresses to
                                    any personally identifiable information, if
                                    your blockchain address is linked to your
                                    identity off our Services, transactions
                                    could be attributable to you.
                                </Text>
                            </Box>

                            {/* Data Retention */}
                            <Box>
                                <Heading size="md" mb={3}>
                                    Data Retention
                                </Heading>
                                <Text mb={4}>
                                    We retain audited data indefinitely.
                                    Un-audited data is retained for 3 years.
                                </Text>
                            </Box>

                            {/* Age Restrictions */}
                            <Box>
                                <Heading size="md" mb={3}>
                                    Age Restrictions
                                </Heading>
                                <Text mb={4}>
                                    Our Services are only available to
                                    individuals who are 18 years of age or
                                    older. We do not knowingly collect
                                    information from anyone under 18.
                                </Text>
                            </Box>

                            {/* Contact Information */}
                            <Box>
                                <Heading size="md" mb={3}>
                                    Contact Us
                                </Heading>
                                <Text mb={2}>
                                    If you have any questions about this Policy,
                                    please contact us at:
                                </Text>
                                <Link
                                    href="mailto:support@onlyX.io"
                                    color="blue.500"
                                >
                                    support@onlyX.io
                                </Link>
                            </Box>
                        </VStack>
                    </ModalBody>

                    <ModalFooter borderTop="1px" borderColor="gray.200" mt={4}>
                        <Button colorScheme="blue" onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        );
    };

    const TermsSection = ({ section }) => {
        if (section.type === 'warning') {
            return (
                <Box
                    p={4}
                    bg="red.50"
                    borderRadius="md"
                    borderWidth="1px"
                    borderColor="red.200"
                >
                    <Text fontWeight="bold" color="red.600">
                        {section.title}:
                    </Text>
                    <Text>{section.content}</Text>
                </Box>
            );
        }

        return (
            <Box mb={6}>
                <Heading size="md" mb={3}>
                    {section.title}
                </Heading>
                {section.content && <Text mb={4}>{section.content}</Text>}
                {section.subsections && section.subsections.length > 0 && (
                    <Accordion allowMultiple>
                        {section.subsections.map((subsection, idx) => (
                            <AccordionItem key={idx}>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left">
                                        <Text fontWeight="medium">
                                            {subsection.title}
                                        </Text>
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    <Text>{subsection.content}</Text>
                                </AccordionPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>
                )}
            </Box>
        );
    };

    const TermsOfServiceModal = ({ isOpen, onClose }) => {
        return (
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                size="4xl"
                scrollBehavior="inside"
            >
                <ModalOverlay backdropFilter="blur(10px)" />
                <ModalContent maxH="85vh">
                    <ModalHeader>
                        <Heading size="lg">Terms of Service</Heading>
                        <Text fontSize="sm" color="gray.600" mt={2}>
                            Last updated {termsOfService.metadata.lastUpdated}
                        </Text>
                    </ModalHeader>
                    <ModalCloseButton />

                    <ModalBody>
                        <VStack align="stretch" spacing={6}>
                            {termsOfService.sections.map((section, idx) => (
                                <TermsSection key={idx} section={section} />
                            ))}
                        </VStack>
                    </ModalBody>

                    <ModalFooter borderTop="1px" borderColor="gray.200" mt={4}>
                        <Button colorScheme="blue" onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        );
    };

    const AboutUsModal = ({ isOpen, onClose }) => {
        return (
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                size="4xl"
                scrollBehavior="inside"
            >
                <ModalOverlay backdropFilter="blur(10px)" />
                <ModalContent maxH="85vh">
                    <ModalHeader>
                        <Heading size="lg">About Only X</Heading>
                    </ModalHeader>
                    <ModalCloseButton />

                    <ModalBody>
                        <VStack align="stretch" spacing={6}>
                            {/* Introduction Section */}
                            <Box>
                                <Text mb={4}>
                                    Only X is pioneering the future of gold
                                    trading with the world's first Federated
                                    Gold Blockchain. We're revolutionizing how
                                    gold is tokenized, traded, and utilized in
                                    the digital economy.
                                </Text>
                                <Text mb={4}>
                                    At the heart of our ecosystem is the
                                    innovative StableGold token (tGOLD), a
                                    gold-backed digital asset that bridges
                                    traditional gold markets with cutting-edge
                                    decentralized finance (DeFi). Our platform
                                    unifies diverse gold assets - from mines to
                                    bullion reserves - into a single, highly
                                    liquid trading environment.
                                </Text>
                            </Box>

                            {/* Mission Section */}
                            <Box>
                                <Heading size="md" mb={3}>
                                    Our Mission
                                </Heading>
                                <Text mb={4}>
                                    We aim to democratize access to gold
                                    investments, enhance market liquidity, and
                                    bring unprecedented transparency to the gold
                                    trading process. By leveraging blockchain
                                    technology and artificial intelligence,
                                    we're creating a more efficient, accessible,
                                    and secure gold market for investors
                                    worldwide.
                                </Text>
                            </Box>

                            {/* What Sets Us Apart Section */}
                            <Box>
                                <Heading size="md" mb={3}>
                                    What Sets Us Apart
                                </Heading>
                                <UnorderedList spacing={2} pl={4}>
                                    <ListItem>
                                        <Text
                                            fontWeight="bold"
                                            display="inline"
                                        >
                                            Federated Gold Blockchain:
                                        </Text>
                                        {
                                            ' Our unique approach aggregates multiple gold sources, creating a robust and diversified backing for our tGOLD token.'
                                        }
                                    </ListItem>
                                    <ListItem>
                                        <Text
                                            fontWeight="bold"
                                            display="inline"
                                        >
                                            AI-Driven Exchange:
                                        </Text>
                                        {
                                            ' Our platform utilizes advanced AI to optimize trading strategies and risk management.'
                                        }
                                    </ListItem>
                                    <ListItem>
                                        <Text
                                            fontWeight="bold"
                                            display="inline"
                                        >
                                            Global Real-Time Settlement:
                                        </Text>
                                        {
                                            ' Through our patented SWIFT integration, we offer instant settlement to fiat currencies.'
                                        }
                                    </ListItem>
                                    <ListItem>
                                        <Text
                                            fontWeight="bold"
                                            display="inline"
                                        >
                                            High-Yield Opportunities:
                                        </Text>
                                        {
                                            ' Gold owners and tGOLD holders can stake their assets to earn competitive yields.'
                                        }
                                    </ListItem>
                                    <ListItem>
                                        <Text
                                            fontWeight="bold"
                                            display="inline"
                                        >
                                            Unparalleled Transparency:
                                        </Text>
                                        {
                                            ' All transactions and gold reserves are recorded on the blockchain and regularly audited.'
                                        }
                                    </ListItem>
                                    <ListItem>
                                        <Text
                                            fontWeight="bold"
                                            display="inline"
                                        >
                                            Regulatory Compliance:
                                        </Text>
                                        {
                                            ' We adhere to USA and EU banking regulations, ensuring a secure and compliant trading environment.'
                                        }
                                    </ListItem>
                                </UnorderedList>
                            </Box>

                            {/* Vision Section */}
                            <Box>
                                <Heading size="md" mb={3}>
                                    Our Vision
                                </Heading>
                                <Text mb={4}>
                                    At Only X, we envision a future where buying
                                    or trading gold is as easy as clicking your
                                    mouse. We're committed to continually
                                    innovating and expanding our services to
                                    meet the evolving needs of both traditional
                                    gold investors and crypto enthusiasts.
                                </Text>
                                <Text mb={4}>
                                    Join us in reshaping the gold market for the
                                    digital age. Whether you're a gold owner
                                    looking to tokenize your assets, an investor
                                    seeking new opportunities, or a trader in
                                    search of a more efficient platform, Only X
                                    is your gateway to the future of gold.
                                </Text>
                            </Box>
                        </VStack>
                    </ModalBody>

                    <ModalFooter borderTop="1px" borderColor="gray.200" mt={4}>
                        <Button colorScheme="blue" onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        );
    };

    const RedditIcon = () => (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
        >
            <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
            <path d="M14.5 12.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
            <path d="M9.5 12.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
            <path d="M16.5 16.5c-1.5 1.26-3 2-4.5 2s-3-.74-4.5-2" />
        </svg>
    );

    const BlueskyIcon = () => (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
        >
            <path d="M12 2L3 6v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V6l-9-4z" />
        </svg>
    );

    const SupportModal = ({ isOpen, onClose }) => {
        return (
            <Modal isOpen={isOpen} onClose={onClose} size="lg">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader className="text-3xl font-normal">
                        Support & Social Media
                    </ModalHeader>
                    <ModalCloseButton />

                    <ModalBody>
                        <VStack align="stretch" spacing={8}>
                            {/* Email Support Section */}
                            <Box>
                                <HStack spacing={3}>
                                    <Mail className="w-6 h-6" />
                                    <Text className="text-xl">
                                        Email Support
                                    </Text>
                                </HStack>
                                <Text className="ml-9">support@onlyx.io</Text>
                            </Box>

                            {/* Connect With Us Section */}
                            <VStack align="stretch" spacing={6}>
                                <Text className="text-xl">Connect With Us</Text>

                                {/* X (Twitter) */}
                                <VStack align="start" spacing={1}>
                                    <HStack spacing={3}>
                                        <X className="w-6 h-6" />
                                        <Text>X (Twitter)</Text>
                                    </HStack>
                                    <HStack spacing={2} className="ml-9">
                                        <Link
                                            target="_blank"
                                            href={`https://x.com/OnlyXOfficial`}
                                        >
                                            @OnlyXOfficial
                                        </Link>
                                        <ExternalLink className="w-4 h-4" />
                                    </HStack>
                                </VStack>

                                {/* Reddit */}
                                <VStack align="start" spacing={1}>
                                    <HStack spacing={3}>
                                        <RedditIcon />
                                        <Text>Reddit</Text>
                                    </HStack>
                                    <HStack spacing={2} className="ml-9">
                                        <Link
                                            target="_blank"
                                            href={`https://www.reddit.com/user/OnlyXOfficial/`}
                                        >
                                            u/OnlyXOfficial
                                        </Link>
                                        <ExternalLink className="w-4 h-4" />
                                    </HStack>
                                </VStack>

                                {/* Bluesky */}
                                <VStack align="start" spacing={1}>
                                    <HStack spacing={3}>
                                        <BlueskyIcon />
                                        <Text>Bluesky</Text>
                                    </HStack>
                                    <HStack spacing={2} className="ml-9">
                                        <Link
                                            target="_blank"
                                            href={`https://bsky.app/profile/onlyxofficial.bsky.social`}
                                        >
                                            @onlyxofficial.bsky.social
                                        </Link>
                                        <ExternalLink className="w-4 h-4" />
                                    </HStack>
                                </VStack>
                            </VStack>
                        </VStack>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            onClick={onClose}
                            className="bg-blue-500 text-white px-8 py-2 rounded-md"
                        >
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        );
    };

    const HomePage = () => (
        <div className="main-container w-[1512px] h-[982px] bg-[#fff] relative overflow-hidden mx-auto my-0">
            <span className="flex w-[400px] h-[96px] justify-center items-start font-['Inter'] text-[96px] font-bold leading-[96px] text-[#1e3a8a] relative text-center whitespace-nowrap z-[8] mt-[165px] mr-0 mb-0 ml-[555px]">
                OnlyX.io
            </span>
            <span className="flex w-[607px] h-[40px] justify-center items-start font-['Inter'] text-[36px] font-bold leading-[40px] text-[#1e3a8a] relative text-center whitespace-nowrap z-[9] mt-[20px] mr-0 mb-0 ml-[452px]">
                Decentralized Perpetual Exchange
            </span>
            <div className="flex w-[394px] pt-[10px] pr-[10px] pb-[10px] pl-[10px] gap-[4px] justify-center items-center flex-nowrap relative z-10 mt-[41px] mr-0 mb-0 ml-[570px]">
                <span className="flex w-[350px] h-[28px] justify-center items-start shrink-0 basis-auto font-['Inter'] text-[20px] font-semibold leading-[28px] text-[#1e3a8a] relative text-center whitespace-nowrap z-[11]">
                    Connect your wallet to start trading.
                </span>
                <div className="w-[20px] h-[20px] shrink-0 bg-cover bg-no-repeat relative z-[12]" />
            </div>
            <div className="flex w-[70px] gap-[10px] items-center flex-nowrap relative z-[16] mt-[-2px] mr-0 mb-0 ml-[720px]">
                <div className="w-[33px] h-[33px] shrink-0 bg-cover bg-no-repeat relative z-[17]" />
                <div className="w-[27px] h-[27px] shrink-0 bg-cover bg-no-repeat relative z-[18]" />
            </div>
        </div>
    );

    return (
        <>
            <div style={gradientStyle}>
                <VStack alignItems="center" flexDirection="column">
                    <BodyContent />
                    <Footer />
                </VStack>
                <SelectWalletModal isOpen={isOpen} closeModal={onClose} />
            </div>
        </>
    );
}
