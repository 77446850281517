import { useState, useEffect } from 'react';
import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import BalanceModal from './BalanceModal.js';
import DepositModal from './DepositModal.js';
import ReactTooltip from 'react-tooltip';
const HDWalletProvider = require('@truffle/hdwallet-provider');
const config = require('./configs/tusd_abi.js');
const PORTFOLIO_ABI = config.portfolioAbi;

export default function Transactions() {
    const [records, setRecords] = useState([]);
    const [web3, setWeb3] = useState(null);
    const [metamaskAccount, setMetamaskAccount] = useState([]);
    const [contract, setContract] = useState(null);
    const [openPositions, setOpenPositions] = useState(null);
    const [perpetuals, setPerpetuals] = useState([]);
    const [amountInvested, setAmountInvested] = useState(0);
    const [currentValuation, setCurrentValuation] = useState(0);
    const [balance, setBalance] = useState(null);
    const toast = useToast();
    const [profitLoss, setProfitLoss] = useState(0);
    const [sidechainWeb3, setSidechainWeb3] = useState(null);
    const [sidechainAccounts, setSidechainAccounts] = useState([]);
    const [portfolioAddress, setPortfolioAddress] = useState(null);
    const [privateKeys, setPrivateKeys] = useState(null);
    const [providerOrUrl, setProviderOrUrl] = useState(null);

    const [portfolioCurrentPage, setPortfolioCurrentPage] = useState(1);
    const portfolioItemsPerPage = 7;

    const serverURL = process.env.REACT_APP_SERVER_URL;

    useEffect(() => {
        const fetchSecrets = async () => {
            try {
                const response = await fetch(`${serverURL}/api/secrets`);
                const data = await response.json();

                setPortfolioAddress(data.portfolioAddress);
                setProviderOrUrl(data.providerOrUrl);

                let formattedPrivateKeys = data.privateKeys;

                if (typeof formattedPrivateKeys === 'string') {
                    try {
                        formattedPrivateKeys = JSON.parse(formattedPrivateKeys);
                    } catch (e) {
                        formattedPrivateKeys = [formattedPrivateKeys];
                    }
                }

                formattedPrivateKeys = formattedPrivateKeys.map((key) =>
                    key.startsWith('0x') ? key : `0x${key}`
                );

                setPrivateKeys(formattedPrivateKeys);
            } catch (error) {
                console.error('Error fetching secrets:', error);
            }
        };

        fetchSecrets();
    }, []);

    useEffect(() => {
        if (!privateKeys || !providerOrUrl || privateKeys.length === 0) {
            return;
        }

        let provider;
        async function fetchWeb3Data() {
            try {
                const token = localStorage.getItem('jwtToken');
                if (!token) {
                    window.location.href = '/sign';
                    return;
                }
                provider = await detectEthereumProvider();
                if (!provider) {
                    console.error('No Ethereum provider detected.');
                    return;
                }

                await provider.request({ method: 'eth_requestAccounts' });

                const web3Instance = new Web3(provider);
                setWeb3(web3Instance);

                const metamaskAccounts = await web3Instance.eth.getAccounts();
                setMetamaskAccount(metamaskAccounts);

                const response = await fetch(
                    `${serverURL}/api/init-transaction`,
                    {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            privateKeys,
                            providerOrUrl,
                            metamaskAccounts,
                        }),
                    }
                );

                if (!response.ok) {
                    throw new Error('Failed to fetch Web3 data');
                }

                const data = await response.json();
                setSidechainAccounts(data.accounts);

                const sidechainWeb3Instance = new Web3(providerOrUrl);
                setSidechainWeb3(sidechainWeb3Instance);

                const contractInstance = new sidechainWeb3Instance.eth.Contract(
                    PORTFOLIO_ABI,
                    portfolioAddress
                );

                setContract(contractInstance);

                if (contractInstance && metamaskAccounts.length > 0) {
                    setBalance(data.balance);
                    setPerpetuals(data.fetchedPerpetuals);
                    setOpenPositions(data.userPositions);
                    setRecords(data.userRecords);
                    //console.log('data', data);
                }
            } catch (error) {
                console.error('Error fetching Web3 data:', error);
            }
        }

        fetchWeb3Data();
    }, [privateKeys, providerOrUrl]);

    useEffect(() => {
        if (contract && metamaskAccount.length > 0) {
            //handleGetAllRecords();
            //handleOpenPositions();
            getUserRecords();
        }
    }, [contract, metamaskAccount]);

    // const handleGetAllRecords = async () => {
    //     if (!contract || accounts.length === 0) {
    //         console.error('Contract not initialized or no accounts available.');
    //         return;
    //     }
    //     try {
    //         const userAddy = accounts[0];
    //         const userRecords = await contract.methods
    //             .searchRecordsByAddress(userAddy, 0)
    //             .call();
    //         console.log('records', userRecords);
    //         setRecords(userRecords);
    //     } catch (error) {
    //         console.error('Error searching records:', error);
    //     }
    // };

    // const handleOpenPositions = async () => {
    //     if (!contract || accounts.length === 0) {
    //         console.error('Contract not initialized or no accounts available.');
    //         return;
    //     }
    //     try {
    //         const userAddy = accounts[0];
    //         const userPositions = await contract.methods
    //             .getOpenPositions(userAddy)
    //             .call();

    //         if (!userPositions || userPositions.length === 0) {
    //             console.log('No open positions found.');
    //             return;
    //         }

    //         const fetchedPerpetuals = await contract.methods
    //             .getAllPerpetuals()
    //             .call();
    //         setPerpetuals(fetchedPerpetuals);
    //         setOpenPositions(userPositions);
    //         console.log('user postitions', userPositions);
    //     } catch (error) {
    //         console.error('Error searching records:', error);
    //     }
    // };

    const navigate = useNavigate();

    const handleClosePositions = async (position, index) => {
        console.log('open detail page change', position, index);
        navigate('/openDetail', { state: { position, index } });
    };

    // const getBalance = async (contractInstance, account) => {
    //     try {
    //         const balance = await contractInstance.methods
    //             .getNetBalance(account)
    //             .call();
    //         setBalance(
    //             parseFloat(
    //                 Web3.utils.fromWei(balance.toString(), 'ether')
    //             ).toFixed(2)
    //         );
    //     } catch (error) {
    //         console.error('Error getting balance:', error);
    //     }
    // };

    const getUserRecords = async () => {
        if (!contract) {
            console.error('Contract not initializeds', contract);
            return;
        }
        try {
            // const userAddy = accounts[0];
            // const userRecords = await contract.methods
            //     .searchRecordsByAddress(userAddy, 0)
            //     .call();
            const totalCurrentValuation = records.reduce((total, record) => {
                const valuation = Number(record.currentValuation || '0');
                return total + valuation;
            }, 0);
            setCurrentValuation(totalCurrentValuation.toFixed(2));

            const totalAmountInvested = records.reduce((total, record) => {
                const valuation = Number(record.amountInvested || '0');
                return total + valuation;
            }, 0);
            setAmountInvested(totalAmountInvested);
        } catch (error) {
            console.error('Error searching records:', error);
        }
    };
    const copyToClipboard = (text) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                toast({
                    title: 'Copied to clipboard!',
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    position: 'top',
                });
            })
            .catch((error) => {
                console.error('Error copying to clipboard:', error);
                toast({
                    title: 'Failed to copy to clipboard.',
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                    position: 'top',
                });
            });
    };
    useEffect(() => {
        const profitLossValue =
            Number(amountInvested) - Number(currentValuation);
        setProfitLoss(profitLossValue.toFixed(2));
    }, [amountInvested, currentValuation]);

    const portfolioTotalPages = Math.ceil(
        (openPositions?.length || 0) / portfolioItemsPerPage
    );
    const portfolioCurrentItems =
        openPositions?.slice(
            (portfolioCurrentPage - 1) * portfolioItemsPerPage,
            portfolioCurrentPage * portfolioItemsPerPage
        ) || [];

    const handlePortfolioPageChange = (page) => {
        setPortfolioCurrentPage(page);
    };
    const filteredRecords = records.map((record) => {
        const transactionHashData = record.transactionHash || null;
        let transactionHash = transactionHashData;

        if (transactionHashData instanceof Uint8Array) {
            transactionHash =
                '0x' +
                Array.from(transactionHashData)
                    .map((b) => b.toString(16).padStart(2, '0'))
                    .join('');
        } else if (transactionHashData) {
            transactionHash = transactionHashData.toString();
        }

        // Determine the method based on transactionHash and direction
        let method;
        const isValidTransactionHash =
            transactionHash &&
            typeof transactionHash === 'string' &&
            transactionHash.startsWith('0x') &&
            transactionHash.length === 66; // Ensuring it's a valid Ethereum TX hash

        if (isValidTransactionHash && record.direction === '0') {
            method = 'Deposit';
        } else if (isValidTransactionHash && record.direction === '1') {
            method = 'Withdraw';
        } else if (!isValidTransactionHash && record.direction === '0') {
            method = 'Close Position';
        } else if (!isValidTransactionHash && record.direction === '1') {
            method = 'Open Position';
        }

        return { ...record, method };
    });

    const [transactionCurrentPage, setTransactionCurrentPage] = useState(1);

    const recordsPerPage = 7;
    const indexOfLastRecord = transactionCurrentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = filteredRecords.slice(
        indexOfFirstRecord,
        indexOfLastRecord
    );

    // Calculate total pages based on filtered records
    const transactionTotalPages = Math.ceil(
        filteredRecords.length / recordsPerPage
    );

    const handleTransactionPageChange = (page) => {
        setTransactionCurrentPage(page);
    };

    return (
        <div className="w-[1290px] h-[734px] relative mt-8 mt-[63px] ml-4 ml-[111px]">
            <div className="flex w-[230px] h-[656px] flex-col gap-[18px] top-0 left-0">
                <BalanceModal />
                <DepositModal />
            </div>

            {/* Portfolio Section */}
            <div className="flex flex-col gap-2 items-center w-[1040px] h-[734px] p-2 bg-white rounded-lg border border-blue-200 absolute top-0 left-[250px] ">
                <div className="flex flex-col gap-2 bg-gray-100 rounded-md p-4 w-full">
                    <div className="flex gap-2 items-center">
                        <div className="w-4 h-3 bg-cover bg-no-repeat portfolio-icon"></div>
                        <span className="text-lg lg:text-2xl font-bold text-blue-800">
                            Portfolio
                        </span>
                    </div>
                    <span className="text-xs text-gray-500">
                        Your open positions.
                    </span>
                </div>

                {/* Portfolio Table */}
                <table className="table-auto w-full">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="px-2 lg:px-4 py-2 text-left text-sm font-semibold text-gray-600">
                                Asset
                            </th>
                            <th className="px-2 lg:px-4 py-2 text-left text-sm font-semibold text-gray-600">
                                Name
                            </th>
                            <th className="px-2 lg:px-4 py-2 text-left text-sm font-semibold text-gray-600">
                                Open Date
                            </th>
                            <th className="px-2 lg:px-4 py-2 text-left text-sm font-semibold text-gray-600">
                                Position
                            </th>
                            <th className="px-2 lg:px-4 py-2 text-left text-sm font-semibold text-gray-600">
                                Purchased Amount
                            </th>
                            {/* <th className="px-2 lg:px-4 py-2 text-left text-sm font-semibold text-gray-600">
                                PnL
                            </th> */}
                            <th className="px-2 lg:px-4 py-2 text-left text-sm font-semibold text-gray-600">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* {console.log('portfolio', portfolioCurrentItems)} */}
                        {portfolioCurrentItems &&
                        portfolioCurrentItems.length > 0 ? (
                            portfolioCurrentItems.map((position, index) => {
                                const perpetualIndex = parseInt(
                                    position.perpetualId,
                                    10
                                );

                                const matchingPerpetual =
                                    perpetuals[perpetualIndex] || null;
                                return (
                                    <tr key={index} className="border-t">
                                        <td className="px-2 lg:px-4 py-2 text-sm text-gray-600">
                                            {matchingPerpetual
                                                ? matchingPerpetual.asset
                                                : 'N/A'}
                                        </td>
                                        <td className="px-2 lg:px-4 py-2 text-sm text-gray-600">
                                            {matchingPerpetual
                                                ? matchingPerpetual.name
                                                : 'N/A'}
                                        </td>
                                        <td className="px-2 lg:px-4 py-2 text-sm text-gray-600">
                                            {position.purchaseTime}
                                        </td>
                                        <td
                                            className={`px-2 lg:px-4 py-2 text-sm ${
                                                position.isLong
                                                    ? 'text-[#10b981]'
                                                    : 'text-[#1e3a8a]'
                                            }`}
                                        >
                                            <span
                                                className={`${
                                                    position.isLong
                                                        ? 'long-icon'
                                                        : 'short-icon'
                                                } inline-block mr-1`}
                                                aria-hidden="true"
                                            ></span>
                                            {position.isLong ? 'Long' : 'Short'}
                                        </td>
                                        <td className="px-2 lg:px-4 py-2 text-sm text-gray-600">
                                            ${position.amount}
                                        </td>
                                        {/* <td className="px-2 lg:px-4 py-2 text-sm text-gray-600">
                                            {profitLoss}
                                        </td> */}
                                        <td>
                                            <button
                                                className="flex w-[90px] h-[40px] lg:h-[45px] justify-center items-center bg-[#D3D3D3] rounded-[24px] text-white"
                                                onClick={() =>
                                                    handleClosePositions(
                                                        position,
                                                        perpetualIndex
                                                    )
                                                }
                                            >
                                                View
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td
                                    colSpan="6"
                                    className="px-4 py-2 text-center text-sm text-gray-600"
                                >
                                    No open positions available.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {/* Portfolio Pagination */}
                <div className="flex justify-center mt-4">
                    {/* Generate the pagination buttons */}
                    {Array.from(
                        { length: portfolioTotalPages },
                        (_, i) => i + 1
                    )
                        .filter((page) => {
                            return (
                                page === 1 ||
                                page === portfolioTotalPages ||
                                Math.abs(page - portfolioCurrentPage) <= 1
                            );
                        })
                        .reduce((acc, page, index, visiblePages) => {
                            if (
                                index > 0 &&
                                page !== visiblePages[index - 1] + 1
                            ) {
                                acc.push('ellipsis');
                            }
                            acc.push(page);
                            return acc;
                        }, [])
                        .map((item, index) =>
                            item === 'ellipsis' ? (
                                <span key={index} className="px-3 py-1 mx-1">
                                    ...
                                </span>
                            ) : (
                                <button
                                    key={item}
                                    className={`px-3 py-1 mx-1 ${
                                        portfolioCurrentPage === item
                                            ? 'bg-blue-500 text-white'
                                            : 'bg-gray-300'
                                    } rounded`}
                                    onClick={() =>
                                        handlePortfolioPageChange(item)
                                    }
                                >
                                    {item}
                                </button>
                            )
                        )}
                </div>
            </div>

            {/* Transaction History Section */}
            <div className="flex flex-col gap-2 items-center w-[1040px] h-[500px] p-2 bg-white rounded-lg border border-blue-200 absolute top-[550px] left-[250px] z-[100]">
                <div className="flex flex-col gap-2 bg-gray-100 rounded-md p-4 w-full">
                    <div className="flex gap-2 items-center">
                        <div className="w-4 h-3 bg-cover bg-no-repeat transaction-icon"></div>
                        <span className="text-2xl font-bold text-blue-800">
                            Transaction
                        </span>
                    </div>
                    <span className="text-xs text-gray-500">
                        Perpetual transaction history.
                    </span>
                </div>

                {/* Transaction Table */}
                <table className="table-auto w-full">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">
                                From
                            </th>
                            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">
                                To
                            </th>
                            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">
                                Amount
                            </th>
                            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">
                                Method
                            </th>
                            {/* <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">
                                Transaction Hash
                            </th> */}
                            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">
                                Date
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* { {console.log('Current Records', currentRecords)} */}
                        {/* {console.log('Records', records)} */}

                        {currentRecords.map((record, index) => {
                            // Ensure record is an object and extract data safely
                            const fromAddr = record.token || 'N/A';
                            const toAddr = record.addr || 'N/A';
                            const amount = record.amount;

                            const transactionHash =
                                record.transactionHash || 'N/A';
                            const truncatedHash =
                                transactionHash !== 'N/A'
                                    ? `${transactionHash.slice(
                                          0,
                                          6
                                      )}...${transactionHash.slice(-4)}`
                                    : 'N/A';

                            const timestamp = record.timestamp;

                            // Determine transaction method based on available fields
                            let method;
                            if (transactionHash.startsWith('0x')) {
                                method =
                                    record.direction == 0
                                        ? 'Deposit'
                                        : 'Withdraw';
                            } else {
                                method =
                                    record.direction == 0
                                        ? 'Close Position'
                                        : 'Open Position';
                            }

                            return (
                                <tr key={index}>
                                    <td className="px-4 py-2 text-sm text-gray-700">
                                        {fromAddr !== 'N/A' ? (
                                            <>
                                                {fromAddr.slice(0, 6) +
                                                    '...' +
                                                    fromAddr.slice(-4)}
                                                <button
                                                    onClick={() =>
                                                        copyToClipboard(
                                                            fromAddr
                                                        )
                                                    }
                                                    className="copy-icon"
                                                ></button>
                                            </>
                                        ) : (
                                            'N/A'
                                        )}
                                    </td>
                                    <td className="px-4 py-2 text-sm text-gray-700">
                                        {toAddr !== 'N/A' ? (
                                            <>
                                                {toAddr.slice(0, 6) +
                                                    '...' +
                                                    toAddr.slice(-4)}
                                                <button
                                                    onClick={() =>
                                                        copyToClipboard(toAddr)
                                                    }
                                                    className="copy-icon"
                                                ></button>
                                            </>
                                        ) : (
                                            'N/A'
                                        )}
                                    </td>
                                    <td className="px-4 py-2 text-sm text-gray-700">
                                        ${amount}
                                    </td>
                                    <td className="px-4 py-2 text-sm text-gray-700">
                                        {method}
                                    </td>
                                    {/* <td className="px-4 py-2 text-sm text-gray-700">
                                        <a
                                            href={`https://blocks.onlyx.io/tx/${transactionHash}`}
                                            style={{
                                                color: 'blue',
                                                textDecoration: 'none',
                                            }}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {truncatedHash}
                                        </a>
                                    </td> */}
                                    <td className="px-4 py-2 text-sm text-gray-700">
                                        {timestamp}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {/* Transaction Pagination */}

                <div className="flex justify-center mt-4">
                    {(() => {
                        let pages = new Set();

                        let start = Math.max(2, transactionCurrentPage - 1);
                        let end = Math.min(
                            transactionTotalPages - 1,
                            transactionCurrentPage + 1
                        );

                        pages.add(1);

                        if (start > 2) {
                            pages.add('ellipsis');
                        }

                        for (let i = start; i <= end; i++) {
                            pages.add(i);
                        }

                        if (end < transactionTotalPages - 1) {
                            pages.add('ellipsis');
                        }

                        if (transactionTotalPages > 1) {
                            pages.add(transactionTotalPages);
                        }

                        return [...pages].map((item, index) =>
                            item === 'ellipsis' ? (
                                <span key={index} className="px-3 py-1 mx-1">
                                    ...
                                </span>
                            ) : (
                                <button
                                    key={item}
                                    className={`px-3 py-1 mx-1 rounded ${
                                        transactionCurrentPage === item
                                            ? 'bg-blue-500 text-white'
                                            : 'bg-gray-300'
                                    }`}
                                    onClick={() =>
                                        handleTransactionPageChange(item)
                                    }
                                >
                                    {item}
                                </button>
                            )
                        );
                    })()}
                </div>
            </div>
        </div>
    );
}
