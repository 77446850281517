import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';
import { useLocation, useNavigate } from 'react-router-dom';
import DepositModal from './DepositModal.js';
import BalanceModal from './BalanceModal.js';
import ProgressBarModal from './ProgressBarModal.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactTooltip from 'react-tooltip';

const HDWalletProvider = require('@truffle/hdwallet-provider');
const config = require('./configs/tusd_abi.js');
const PORTFOLIO_ABI = config.portfolioAbi;
const BN = Web3.utils.BN;
const { ethers } = require('ethers');

export default function Trade() {
    const { state } = useLocation();
    const { perpetual, index } = state || {};
    const [web3, setWeb3] = useState(null);
    const [metamaskAccounts, setMetamaskAccounts] = useState([]);
    const [sidechainWeb3, setSidechainWeb3] = useState(null);
    const [sidechainAccounts, setSidechainAccounts] = useState([]);
    const [contract, setContract] = useState(null);
    const [buyAmount, setBuyAmount] = useState(0);
    const [leverage, setLeverage] = useState(1);
    const [otmPrice, setOtmPrice] = useState(0);
    const [leverageFee, setLeverageFee] = useState(0);
    const [otmPriceFee, setOtmPriceFee] = useState(0);
    const [total, setTotal] = useState(0);
    const [balance, setBalance] = useState(0);
    const [amountInvested, setAmountInvested] = useState(0);
    const [currentValuation, setCurrentValuation] = useState(0);
    const [error, setError] = useState('');
    const [showTradeButton, setShowTradeButton] = useState(false);
    const [progress, setProgress] = useState(0);
    const [transactionLink, setTransactionLink] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLocked, setIsLocked] = useState(false);
    const navigate = useNavigate();
    const [portfolioAddress, setPortfolioAddress] = useState(null);
    const [privateKeys, setPrivateKeys] = useState(null);
    const [providerOrUrl, setProviderOrUrl] = useState(null);

    const serverURL = process.env.REACT_APP_SERVER_URL;
    useEffect(() => {
        const fetchSecrets = async () => {
            try {
                const response = await fetch(`${serverURL}/api/secrets`);
                const data = await response.json();

                setPortfolioAddress(data.portfolioAddress);
                setProviderOrUrl(data.providerOrUrl);

                let formattedPrivateKeys = data.privateKeys;

                if (typeof formattedPrivateKeys === 'string') {
                    try {
                        formattedPrivateKeys = JSON.parse(formattedPrivateKeys);
                    } catch (e) {
                        formattedPrivateKeys = [formattedPrivateKeys];
                    }
                }

                formattedPrivateKeys = formattedPrivateKeys.map((key) =>
                    key.startsWith('0x') ? key : `0x${key}`
                );
                setPrivateKeys(formattedPrivateKeys);
            } catch (error) {
                console.error('Error fetching secrets:', error);
            }
        };

        fetchSecrets();
    }, []);

    useEffect(() => {
        if (!privateKeys || !providerOrUrl || privateKeys.length === 0) {
            return;
        }
        let provider;
        async function fetchWeb3Data() {
            try {
                const token = localStorage.getItem('jwtToken');
                if (!token) {
                    window.location.href = '/sign';
                    return;
                }
                provider = await detectEthereumProvider();
                if (!provider) {
                    console.error('No Ethereum provider detected.');
                    return;
                }

                await provider.request({ method: 'eth_requestAccounts' });

                const web3Instance = new Web3(provider);
                setWeb3(web3Instance);
                const metamaskAccounts = await web3Instance.eth.getAccounts();
                setMetamaskAccounts(metamaskAccounts);

                const response = await fetch(`${serverURL}/api/init-trade`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        privateKeys,
                        providerOrUrl,
                        metamaskAccounts,
                    }),
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch Web3 data');
                }

                const data = await response.json();
                setSidechainAccounts(data.accounts);

                const sidechainWeb3Instance = new Web3(providerOrUrl);
                setSidechainWeb3(sidechainWeb3Instance);

                const contractInstance = new sidechainWeb3Instance.eth.Contract(
                    PORTFOLIO_ABI,
                    portfolioAddress
                );

                setContract(contractInstance);

                if (contractInstance && metamaskAccounts.length > 0) {
                    setBalance(data.balance);
                }
            } catch (error) {
                console.error('Error fetching Web3 data:', error);
            }
        }

        fetchWeb3Data();
    }, [privateKeys, providerOrUrl]);

    const handleBuyAmountChange = (amount) => {
        const numericValue = parseFloat(amount);
        if (numericValue > 0 || amount === '') {
            setBuyAmount(amount);
        }
    };

    const handleLeverageChange = (leverage) => {
        setLeverage(leverage);
    };

    const handleOtmPriceChange = (value) => {
        const newOtmPrice = parseFloat(value);

        // Always allow the input to update
        setOtmPrice(value);

        // Only validate if the input is a valid number
        if (!isNaN(newOtmPrice)) {
            // Convert perpetual price to Wei first
            const perpetualPriceInWei = Web3.utils.toWei(
                perpetual.price.toString().replace(/,/g, ''), // Remove commas
                'ether'
            );

            // Calculate min and max prices in Wei using BN
            const minOtmPriceInWei = new BN(perpetualPriceInWei)
                .mul(new BN('7'))
                .div(new BN('10')); // 70%
            const maxOtmPriceInWei = new BN(perpetualPriceInWei)
                .mul(new BN('13'))
                .div(new BN('10')); // 130%

            // Convert newOtmPrice to Wei without scientific notation
            const newOtmPriceInWei = Web3.utils.toWei(
                newOtmPrice.toFixed(18),
                'ether'
            ); // Use toFixed to avoid scientific notation

            // Ensure the otmPrice is within 30% of perpetual price
            if (
                new BN(newOtmPriceInWei).lt(minOtmPriceInWei) ||
                new BN(newOtmPriceInWei).gt(maxOtmPriceInWei)
            ) {
                //** add pop up value to display the values
                console.log(
                    `OTM Price must be between ${Web3.utils.fromWei(
                        minOtmPriceInWei.toString(),
                        'ether'
                    )} and ${Web3.utils.fromWei(
                        maxOtmPriceInWei.toString(),
                        'ether'
                    )}`
                );
            }
        }
    };

    const navigateBack = () => {
        navigate(-1); // Takes the user to the previous page
    };

    const getBalance = async (contractInstance, account) => {
        try {
            const balance = await contractInstance.methods
                .getNetBalance(account)
                .call();
            setBalance(balance);
        } catch (error) {
            console.error('Error getting balance:', error);
        }
    };

    const buyPerpetuals = async () => {
        try {
            console.log('Starting to buy perpetuals');
            const buyer = metamaskAccounts[0];
            const amountWei = parseFloat(
                sidechainWeb3.utils.toWei(buyAmount, 'ether')
            );
            const totalWei = parseFloat(
                sidechainWeb3.utils.toWei(total.toString(), 'ether')
            );

            if (balance < totalWei) {
                toast.error('Insufficient balance to complete the purchase.');
                return;
            }
            setIsModalOpen(true);
            setProgress(30);

            // Handle API response
            const response = await fetch(`${serverURL}/api/buyPerpetual`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    buyer: buyer,
                    id: index,
                    amount: buyAmount,
                    leverage: leverage,
                    otmPrice: otmPrice,
                }),
            }).catch((error) => console.error('Fetch error:', error));

            const data = await response.json();
            console.log('data', data);
            const transactionHash = data.hash;
            if (transactionHash) {
                const link = `https://blocks.onlyx.io/tx/${transactionHash}`;
                setTransactionLink(link);
                setProgress(100);
                toast.success('Transaction confirmed!');
                setIsModalOpen(false);
                navigate(-1);
            } else {
                throw new Error('Transaction hash not found.');
            }
        } catch (error) {
            console.error('Error:', error);
            setIsModalOpen(false); // Ensure modal is closed on error
        }
    };

    const getFees = async () => {
        try {
            console.log(`index and perp ${perpetual} index ${index}`);

            setIsLocked(true);
            const response = await fetch(
                `${serverURL}/api/buyPerpetual/getFees`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        index: index,
                        amount: buyAmount,
                        leverage: leverage,
                        otmPrice: otmPrice,
                    }),
                }
            ).catch((error) => console.error('Fetch error:', error));

            console.log('Waiting for emitted events...');
            const data = await response.json();
            console.log('data', data);
            const leverageFee = data.leverageFee;
            //const formattedLeverageFee = parseFloat(leverageFee).toFixed(2);
            setLeverageFee(leverageFee);

            const otmPriceFee = data.otmPriceFee;
            //const formattedOtmPriceFee = parseFloat(otmPriceFee).toFixed(2);
            setOtmPriceFee(otmPriceFee);

            console.log('buy amount', buyAmount);
            const totalPrice =
                parseFloat(leverageFee.replace(/,/g, '')) +
                parseFloat(otmPriceFee.replace(/,/g, '')) +
                parseFloat(buyAmount.replace(/,/g, ''));
            console.log(
                'leverage ' +
                    parseFloat(leverageFee.replace(/,/g, '')) +
                    ' otmPrice ' +
                    parseFloat(otmPriceFee.replace(/,/g, '')) +
                    ' buyAmount ' +
                    parseFloat(buyAmount.replace(/,/g, ''))
            );

            setTotal(totalPrice);

            setShowTradeButton(true);
        } catch (error) {
            console.error('Error:', error);
            setIsModalOpen(false);
        }
    };

    return (
        <div className="w-full lg:w-[1290px] h-auto lg:h-[734px] relative mt-8 lg:mt-[63px] ml-4 lg:ml-[111px]">
            <div className="flex w-full lg:w-[230px] h-auto lg:h-[656px] flex-col gap-4 lg:gap-[18px] top-0 left-0">
                <div className="flex items-center gap-2">
                    <div className="w-4 h-3 bg-cover bg-no-repeat back-icon"></div>
                    <button
                        className="text-gray-600 text-sm left-5 lg:left-20"
                        onClick={navigateBack}
                    >
                        Back
                    </button>
                </div>

                {/* Deposit Section */}
                <BalanceModal />
                <DepositModal />
            </div>

            {/* Trade Section */}
            <div className="flex flex-col gap-2 items-center w-full lg:w-[1040px] h-auto lg:h-[834px] p-2 bg-white rounded-lg border border-blue-200 absolute top-9 left-[250px] ">
                <div className="flex flex-col gap-2 bg-gray-100 rounded-md p-4 w-full">
                    <div className="flex gap-2 items-center">
                        <div className="w-4 h-3 bg-cover bg-no-repeat market-icon"></div>
                        <span className="text-lg lg:text-2xl font-bold text-blue-800">
                            Trade
                        </span>
                    </div>
                    <span className="text-xs text-gray-500">
                        Open a position
                    </span>
                </div>

                <div className="flex flex-col justify-between self-stretch shrink-0 flex-nowrap relative pt-4">
                    {/* Perpetual Info */}
                    <div className="mb-4 flex justify-between">
                        <span className="h-[30px] shrink-0 basis-auto font-['Inter'] text-[20px] font-bold leading-[16px] text-[#111827] relative text-left whitespace-nowrap pt-4">
                            Name&nbsp;
                            <button
                                data-tip="Total of Account Balance and Open Positions"
                                id="myButton"
                                className="tooltip-icon"
                            ></button>
                            <ReactTooltip anchorId="myButton" />
                        </span>
                        <p className="text-[16px] font-bold text-gray-600">
                            {perpetual.name}
                        </p>
                    </div>
                    <div className="mb-4 flex justify-between">
                        <span className="h-[30px] shrink-0 basis-auto font-['Inter'] text-[20px] font-bold leading-[16px] text-[#111827] relative text-left whitespace-nowrap pt-4">
                            Contract Address&nbsp;
                            <button
                                data-tip="Total of Account Balance and Open Positions"
                                id="myButton"
                                className="tooltip-icon"
                            ></button>
                            <ReactTooltip anchorId="myButton" />
                        </span>
                        <p className="text-[16px] font-bold text-gray-600">{`${perpetual.perpetualAddress.slice(
                            0,
                            6
                        )}...${perpetual.perpetualAddress.slice(-4)}`}</p>
                    </div>
                    <div className="mb-4 flex justify-between">
                        <span className="h-[30px] shrink-0 basis-auto font-['Inter'] text-[20px] font-bold leading-[16px] text-[#111827] relative text-left whitespace-nowrap pt-4">
                            Price (tUSD)&nbsp;
                            <button
                                data-tip="Total of Account Balance and Open Positions"
                                id="myButton"
                                className="tooltip-icon"
                            ></button>
                            <ReactTooltip anchorId="myButton" />
                        </span>
                        <p className="text-[16px] font-bold text-gray-600">
                            ${perpetual.price}
                        </p>
                    </div>
                    <div className="mb-4 flex justify-between">
                        <span className="h-[30px] shrink-0 basis-auto font-['Inter'] text-[20px] font-bold leading-[16px] text-[#111827] relative text-left whitespace-nowrap pt-4">
                            Longs vs Shorts&nbsp;
                            <button
                                data-tip="Total of Account Balance and Open Positions"
                                id="myButton"
                                className="tooltip-icon"
                            ></button>
                            <ReactTooltip anchorId="myButton" />
                        </span>
                        <div className="w-[12px] h-[12px] shrink-0 bg-cover bg-no-repeat relative z-[157]" />

                        <div className="flex w-[130px] gap-[5px] items-center shrink-0 flex-nowrap relative z-[158]">
                            <span className="h-[20px] shrink-0 basis-auto font-['Inter'] text-[14px] font-bold leading-[20px] text-[#10b981] relative text-left whitespace-nowrap text-green-600 z-[159]">
                                {perpetual.percentageHigher} %
                            </span>
                            <span className="h-[20px] shrink-0 basis-auto font-['Inter'] text-[14px] font-normal leading-[20px] text-[#1e3a8a] relative text-left whitespace-nowrap text-gray-600 z-[160]">
                                vs
                            </span>
                            <span className="h-[20px] shrink-0 basis-auto font-['Inter'] text-[14px] font-bold leading-[20px] text-[#ef4444] relative text-left whitespace-nowrap text-red-600 z-[161]">
                                {perpetual.percentageLower} %
                            </span>
                        </div>
                    </div>

                    {/* Trade Inputs */}
                    <div className="flex justify-between items-center self-stretch shrink-0 flex-nowrap relative pt-4">
                        <span className="h-[30px] shrink-0 basis-auto font-['Inter'] text-[20px] font-bold leading-[16px] text-[#111827] relative text-left whitespace-nowrap">
                            Amount (tUSD)&nbsp;
                            <button
                                data-tip="Total of Account Balance and Open Positions"
                                id="myButton"
                                className="tooltip-icon"
                            ></button>
                            <ReactTooltip anchorId="myButton" />
                        </span>
                        <input
                            type="number"
                            placeholder="Enter Amount"
                            value={buyAmount || ''}
                            onChange={(e) =>
                                handleBuyAmountChange(e.target.value)
                            }
                            disabled={isLocked}
                            className="flex w-[200px] pt-0 pr-[5px] pb-0 pl-[5px] gap-[10px] justify-end items-center shrink-0 flex-nowrap rounded-[4px] border-solid border border-[#6b7280] relative overflow-hidden"
                        />
                    </div>

                    <div className="flex justify-between items-center self-stretch shrink-0 flex-nowrap relative pt-4">
                        <span className="h-[30px] shrink-0 basis-auto font-['Inter'] text-[20px] font-bold leading-[16px] text-[#111827] relative text-left whitespace-nowrap">
                            Leverage (1 - 100x)&nbsp;
                            <button
                                data-tip="Total of Account Balance and Open Positions"
                                id="myButton"
                                className="tooltip-icon"
                            ></button>
                            <ReactTooltip anchorId="myButton" />
                        </span>
                        <input
                            type="number"
                            placeholder="Set Leverage"
                            value={leverage || ''}
                            onChange={(e) =>
                                handleLeverageChange(e.target.value)
                            }
                            min="1"
                            max="100"
                            disabled={isLocked}
                            className="flex w-[200px] pt-0 pr-[5px] pb-0 pl-[5px] gap-[10px] justify-end items-center shrink-0 flex-nowrap rounded-[4px] border-solid border border-[#6b7280] relative overflow-hidden"
                        />
                    </div>
                    {/* OTM Price Adjustment */}
                    <div className="flex justify-between items-center self-stretch shrink-0 flex-nowrap relative pt-4">
                        <span className="h-[30px] shrink-0 basis-auto font-['Inter'] text-[20px] font-bold leading-[16px] text-[#111827] relative text-left whitespace-nowrap">
                            OTM Price Adjustment&nbsp;
                            <button
                                data-tip="Total of Account Balance and Open Positions"
                                id="myButton"
                                className="tooltip-icon"
                            ></button>
                            <ReactTooltip anchorId="myButton" />
                        </span>
                        <input
                            type="number"
                            placeholder="Enter OTM Price"
                            value={otmPrice || ''}
                            onChange={(e) =>
                                handleOtmPriceChange(e.target.value)
                            }
                            disabled={isLocked}
                            className="flex w-[200px] pt-0 pr-[5px] pb-0 pl-[5px] gap-[10px] justify-end items-center shrink-0 flex-nowrap rounded-[4px] border-solid border border-[#6b7280] relative overflow-hidden"
                        />
                    </div>

                    <div className="flex justify-between items-center self-stretch shrink-0 flex-nowrap relative pt-4">
                        <span className="h-[30px] shrink-0 basis-auto font-['Inter'] text-[20px] font-bold leading-[16px] text-[#111827] relative text-left whitespace-nowrap">
                            Purchasing Power (tUSD)&nbsp;
                            <button
                                data-tip="Total of Account Balance and Open Positions"
                                id="myButton"
                                className="tooltip-icon"
                            ></button>
                            <ReactTooltip anchorId="myButton" />
                        </span>
                        <span className="h-[30px] shrink-0 basis-auto font-['Inter'] text-[20px] font-bold leading-[16px] text-[#111827] relative text-left whitespace-nowrap z-[185]">
                            $
                            {Number(
                                parseFloat(buyAmount || 0) *
                                    parseFloat(leverage || 0)
                            ).toLocaleString()}
                        </span>
                    </div>
                </div>
                <hr className="my-4 w-full border-t-2 border-black" />
                <div className="flex justify-between items-center self-stretch shrink-0 flex-nowrap relative pt-4">
                    <span className="h-[30px] shrink-0 basis-auto font-['Inter'] text-[20px] font-bold leading-[16px] text-[#6b7280] relative text-left whitespace-nowrap">
                        Leverage Fee (tUSD)&nbsp;
                        <button
                            data-tip="Total of Account Balance and Open Positions"
                            id="myButton"
                            className="tooltip-icon"
                        ></button>
                        <ReactTooltip anchorId="myButton" />
                    </span>
                    <span className="h-[30px] shrink-0 basis-auto font-['Inter'] text-[20px] font-bold leading-[16px] text-[#6b7280] relative text-left whitespace-nowrap z-[185]">
                        ${leverageFee}
                    </span>
                </div>
                <div className="flex justify-between items-center self-stretch shrink-0 flex-nowrap relative pt-4">
                    <span className="h-[30px] shrink-0 basis-auto font-['Inter'] text-[20px] font-bold leading-[16px] text-[#6b7280] relative text-left whitespace-nowrap">
                        OTM Adjustment Fee (tUSD)&nbsp;
                        <button
                            data-tip="Total of Account Balance and Open Positions"
                            id="myButton"
                            className="tooltip-icon"
                        ></button>
                        <ReactTooltip anchorId="myButton" />
                    </span>
                    <span className="h-[30px] shrink-0 basis-auto font-['Inter'] text-[20px] font-bold leading-[16px] text-[#6b7280] relative text-left whitespace-nowrap z-[185]">
                        ${otmPriceFee}
                    </span>
                </div>
                <hr className="my-4 w-full border-t-2 border-black" />

                <div className="flex justify-between items-center self-stretch shrink-0 flex-nowrap relative pt-4">
                    <span className="h-[30px] shrink-0 basis-auto font-['Inter'] text-[20px] font-bold leading-[16px] text-[#111827] relative text-left whitespace-nowrap">
                        Transaction Total (tUSD)&nbsp;
                        <button
                            data-tip="Total of Account Balance and Open Positions"
                            id="myButton"
                            className="tooltip-icon"
                        ></button>
                        <ReactTooltip anchorId="myButton" />
                    </span>
                    <span className="h-[30px] shrink-0 basis-auto font-['Inter'] text-[20px] font-bold leading-[16px] text-[#111827] relative text-left whitespace-nowrap z-[185]">
                        ${Number(total).toLocaleString()}
                    </span>
                </div>

                {/* Open Long/Short Buttons */}
                <div className="flex justify-between mt-6 ml-auto">
                    {!showTradeButton && (
                        <button
                            className="flex w-[200px] h-[44px] pt-[10px] pr-[24px] pb-[10px] pl-[24px] gap-[10px] justify-center items-center shrink-0 flex-nowrap bg-[#3b82f6] rounded-[24px] border-none pointer"
                            onClick={() => getFees(true)}
                        >
                            Get Fees
                        </button>
                    )}

                    {showTradeButton && (
                        <button
                            className="flex w-[200px] h-[44px] pt-[10px] pr-[24px] pb-[10px] pl-[24px] gap-[10px] justify-center items-center shrink-0 flex-nowrap bg-[#10b981] rounded-[24px] border-none relative  pointer"
                            onClick={buyPerpetuals}
                        >
                            Trade
                        </button>
                    )}

                    <ProgressBarModal
                        isOpen={isModalOpen}
                        onRequestClose={() => setIsModalOpen(false)}
                        progress={progress}
                        transactionLink={transactionLink}
                    />
                </div>
            </div>
        </div>
    );
}
