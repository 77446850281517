import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client'; // Updated import
import { ChakraProvider } from '@chakra-ui/react';
import { Web3ReactProvider } from '@web3-react/core';
import { ethers } from 'ethers';

import App from './App';

const getLibrary = (provider) => {
    const library = new ethers.BrowserProvider(provider);
    library.pollingInterval = 8000;
    return library;
};

const rootElement = document.getElementById('root');

const root = createRoot(rootElement);

root.render(
    <StrictMode>
        <ChakraProvider>
            <Web3ReactProvider getLibrary={getLibrary}>
                <App />
            </Web3ReactProvider>
        </ChakraProvider>
    </StrictMode>
);
