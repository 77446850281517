const path = require('path');
const dotenv = require('dotenv');

const config = {
    tUSDAbi: [
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'owner',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'spender',
                    type: 'address',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'value',
                    type: 'uint256',
                },
            ],
            name: 'Approval',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'from',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'to',
                    type: 'address',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'value',
                    type: 'uint256',
                },
            ],
            name: 'Transfer',
            type: 'event',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'spender',
                    type: 'address',
                },
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                },
            ],
            name: 'approve',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool',
                },
            ],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                },
            ],
            name: 'burn',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                },
            ],
            name: 'mint',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'recipient',
                    type: 'address',
                },
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                },
            ],
            name: 'transfer',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool',
                },
            ],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'sender',
                    type: 'address',
                },
                {
                    internalType: 'address',
                    name: 'recipient',
                    type: 'address',
                },
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                },
            ],
            name: 'transferFrom',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool',
                },
            ],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address',
                },
                {
                    internalType: 'address',
                    name: '',
                    type: 'address',
                },
            ],
            name: 'allowance',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address',
                },
            ],
            name: 'balanceOf',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'decimals',
            outputs: [
                {
                    internalType: 'uint8',
                    name: '',
                    type: 'uint8',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'name',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'symbol',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'totalSupply',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
    ],
    USDCAbi: [
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'owner',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'spender',
                    type: 'address',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'value',
                    type: 'uint256',
                },
            ],
            name: 'Approval',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'from',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'to',
                    type: 'address',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'value',
                    type: 'uint256',
                },
            ],
            name: 'Transfer',
            type: 'event',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'spender',
                    type: 'address',
                },
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                },
            ],
            name: 'approve',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool',
                },
            ],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                },
            ],
            name: 'burn',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                },
            ],
            name: 'mint',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'recipient',
                    type: 'address',
                },
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                },
            ],
            name: 'transfer',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool',
                },
            ],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'sender',
                    type: 'address',
                },
                {
                    internalType: 'address',
                    name: 'recipient',
                    type: 'address',
                },
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                },
            ],
            name: 'transferFrom',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool',
                },
            ],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address',
                },
                {
                    internalType: 'address',
                    name: '',
                    type: 'address',
                },
            ],
            name: 'allowance',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address',
                },
            ],
            name: 'balanceOf',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'decimals',
            outputs: [
                {
                    internalType: 'uint8',
                    name: '',
                    type: 'uint8',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'name',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'symbol',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'totalSupply',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
    ],
    usdtAbi: [
        {
            constant: true,
            inputs: [],
            name: 'name',
            outputs: [{ name: '', type: 'string' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: false,
            inputs: [{ name: '_upgradedAddress', type: 'address' }],
            name: 'deprecate',
            outputs: [],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            constant: false,
            inputs: [
                { name: '_spender', type: 'address' },
                { name: '_value', type: 'uint256' },
            ],
            name: 'approve',
            outputs: [],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            constant: true,
            inputs: [],
            name: 'deprecated',
            outputs: [{ name: '', type: 'bool' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: false,
            inputs: [{ name: '_evilUser', type: 'address' }],
            name: 'addBlackList',
            outputs: [],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            constant: true,
            inputs: [],
            name: 'totalSupply',
            outputs: [{ name: '', type: 'uint256' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: false,
            inputs: [
                { name: '_from', type: 'address' },
                { name: '_to', type: 'address' },
                { name: '_value', type: 'uint256' },
            ],
            name: 'transferFrom',
            outputs: [],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            constant: true,
            inputs: [],
            name: 'upgradedAddress',
            outputs: [{ name: '', type: 'address' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [{ name: '', type: 'address' }],
            name: 'balances',
            outputs: [{ name: '', type: 'uint256' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [],
            name: 'decimals',
            outputs: [{ name: '', type: 'uint256' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [],
            name: 'maximumFee',
            outputs: [{ name: '', type: 'uint256' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [],
            name: '_totalSupply',
            outputs: [{ name: '', type: 'uint256' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: false,
            inputs: [],
            name: 'unpause',
            outputs: [],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            constant: true,
            inputs: [{ name: '_maker', type: 'address' }],
            name: 'getBlackListStatus',
            outputs: [{ name: '', type: 'bool' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [
                { name: '', type: 'address' },
                { name: '', type: 'address' },
            ],
            name: 'allowed',
            outputs: [{ name: '', type: 'uint256' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [],
            name: 'paused',
            outputs: [{ name: '', type: 'bool' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [{ name: 'who', type: 'address' }],
            name: 'balanceOf',
            outputs: [{ name: '', type: 'uint256' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: false,
            inputs: [],
            name: 'pause',
            outputs: [],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            constant: true,
            inputs: [],
            name: 'getOwner',
            outputs: [{ name: '', type: 'address' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [],
            name: 'owner',
            outputs: [{ name: '', type: 'address' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [],
            name: 'symbol',
            outputs: [{ name: '', type: 'string' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: false,
            inputs: [
                { name: '_to', type: 'address' },
                { name: '_value', type: 'uint256' },
            ],
            name: 'transfer',
            outputs: [],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            constant: false,
            inputs: [
                { name: 'newBasisPoints', type: 'uint256' },
                { name: 'newMaxFee', type: 'uint256' },
            ],
            name: 'setParams',
            outputs: [],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            constant: false,
            inputs: [{ name: 'amount', type: 'uint256' }],
            name: 'issue',
            outputs: [],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            constant: false,
            inputs: [{ name: 'amount', type: 'uint256' }],
            name: 'redeem',
            outputs: [],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            constant: true,
            inputs: [
                { name: '_owner', type: 'address' },
                { name: '_spender', type: 'address' },
            ],
            name: 'allowance',
            outputs: [{ name: 'remaining', type: 'uint256' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [],
            name: 'basisPointsRate',
            outputs: [{ name: '', type: 'uint256' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [{ name: '', type: 'address' }],
            name: 'isBlackListed',
            outputs: [{ name: '', type: 'bool' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: false,
            inputs: [{ name: '_clearedUser', type: 'address' }],
            name: 'removeBlackList',
            outputs: [],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            constant: true,
            inputs: [],
            name: 'MAX_UINT',
            outputs: [{ name: '', type: 'uint256' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: false,
            inputs: [{ name: 'newOwner', type: 'address' }],
            name: 'transferOwnership',
            outputs: [],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            constant: false,
            inputs: [{ name: '_blackListedUser', type: 'address' }],
            name: 'destroyBlackFunds',
            outputs: [],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                { name: '_initialSupply', type: 'uint256' },
                { name: '_name', type: 'string' },
                { name: '_symbol', type: 'string' },
                { name: '_decimals', type: 'uint256' },
            ],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'constructor',
        },
        {
            anonymous: false,
            inputs: [{ indexed: false, name: 'amount', type: 'uint256' }],
            name: 'Issue',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [{ indexed: false, name: 'amount', type: 'uint256' }],
            name: 'Redeem',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [{ indexed: false, name: 'newAddress', type: 'address' }],
            name: 'Deprecate',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, name: 'feeBasisPoints', type: 'uint256' },
                { indexed: false, name: 'maxFee', type: 'uint256' },
            ],
            name: 'Params',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, name: '_blackListedUser', type: 'address' },
                { indexed: false, name: '_balance', type: 'uint256' },
            ],
            name: 'DestroyedBlackFunds',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [{ indexed: false, name: '_user', type: 'address' }],
            name: 'AddedBlackList',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [{ indexed: false, name: '_user', type: 'address' }],
            name: 'RemovedBlackList',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, name: 'owner', type: 'address' },
                { indexed: true, name: 'spender', type: 'address' },
                { indexed: false, name: 'value', type: 'uint256' },
            ],
            name: 'Approval',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, name: 'from', type: 'address' },
                { indexed: true, name: 'to', type: 'address' },
                { indexed: false, name: 'value', type: 'uint256' },
            ],
            name: 'Transfer',
            type: 'event',
        },
        { anonymous: false, inputs: [], name: 'Pause', type: 'event' },
        { anonymous: false, inputs: [], name: 'Unpause', type: 'event' },
    ],
    portfolioAbi: [
        { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'expirationTime',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: 'currentTime',
                    type: 'uint256',
                },
            ],
            name: 'ExpiredFeeQuote',
            type: 'error',
        },
        {
            inputs: [{ internalType: 'string', name: 'hash', type: 'string' }],
            name: 'FundingRatePaymentNotFound',
            type: 'error',
        },
        {
            inputs: [
                { internalType: 'uint256', name: 'required', type: 'uint256' },
                { internalType: 'uint256', name: 'available', type: 'uint256' },
            ],
            name: 'InsufficientFunds',
            type: 'error',
        },
        { inputs: [], name: 'InsufficientPayment', type: 'error' },
        {
            inputs: [
                { internalType: 'uint256', name: 'leverage', type: 'uint256' },
            ],
            name: 'InvalidLeverage',
            type: 'error',
        },
        {
            inputs: [
                { internalType: 'uint256', name: 'otmPrice', type: 'uint256' },
                { internalType: 'uint256', name: 'unitCost', type: 'uint256' },
            ],
            name: 'InvalidOTMPrice',
            type: 'error',
        },
        {
            inputs: [{ internalType: 'uint256', name: 'id', type: 'uint256' }],
            name: 'InvalidPerpetualId',
            type: 'error',
        },
        {
            inputs: [
                { internalType: 'address', name: 'caller', type: 'address' },
                {
                    internalType: 'address',
                    name: 'expectedOracle',
                    type: 'address',
                },
            ],
            name: 'InvalidPriceUpdate',
            type: 'error',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'pricesLength',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: 'perpetualsLength',
                    type: 'uint256',
                },
            ],
            name: 'MismatchedPricesAndPerpetuals',
            type: 'error',
        },
        { inputs: [], name: 'NotApprovedAddress', type: 'error' },
        {
            inputs: [
                { internalType: 'address', name: 'caller', type: 'address' },
                {
                    internalType: 'address',
                    name: 'expectedOracle',
                    type: 'address',
                },
            ],
            name: 'NotAuthorizedOracle',
            type: 'error',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'perpetualId',
                    type: 'uint256',
                },
            ],
            name: 'PositionAlreadyClosed',
            type: 'error',
        },
        {
            inputs: [
                { internalType: 'bytes32', name: 'quoteId', type: 'bytes32' },
            ],
            name: 'QuoteAlreadyUsed',
            type: 'error',
        },
        {
            inputs: [{ internalType: 'uint256', name: 'id', type: 'uint256' }],
            name: 'ZeroPerpetualPrice',
            type: 'error',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'user',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'perpetualId',
                    type: 'uint256',
                },
                {
                    indexed: false,
                    internalType: 'bytes32',
                    name: 'quoteId',
                    type: 'bytes32',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'leverageFee',
                    type: 'uint256',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'otmPriceFee',
                    type: 'uint256',
                },
            ],
            name: 'FeeQuoteGenerated',
            type: 'event',
        },
        {
            inputs: [
                { internalType: 'address', name: '_addr', type: 'address' },
            ],
            name: 'addApprovedAddress',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                { internalType: 'string', name: '_name', type: 'string' },
                { internalType: 'string', name: '_asset', type: 'string' },
                { internalType: 'uint256', name: '_price', type: 'uint256' },
                { internalType: 'address', name: '_oracle', type: 'address' },
                {
                    internalType: 'uint256',
                    name: '_shelfLife',
                    type: 'uint256',
                },
            ],
            name: 'addPerpetual',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                { internalType: 'address', name: '_token', type: 'address' },
                { internalType: 'address', name: '_addr', type: 'address' },
                { internalType: 'uint256', name: '_amount', type: 'uint256' },
                {
                    internalType: 'enum RecordKeeper.Direction',
                    name: '_direction',
                    type: 'uint8',
                },
                {
                    internalType: 'string',
                    name: '_transactionHash',
                    type: 'string',
                },
            ],
            name: 'addRecord',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [{ internalType: 'address', name: '', type: 'address' }],
            name: 'approvedAddresses',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                { internalType: 'address', name: '_addr', type: 'address' },
                { internalType: 'uint256', name: '_id', type: 'uint256' },
                { internalType: 'uint256', name: '_amount', type: 'uint256' },
                { internalType: 'uint256', name: '_leverage', type: 'uint256' },
                { internalType: 'uint256', name: '_otmPrice', type: 'uint256' },
                { internalType: 'bytes32', name: '_quoteId', type: 'bytes32' },
            ],
            name: 'buyPerpetual',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                { internalType: 'address', name: '', type: 'address' },
                { internalType: 'uint256', name: '', type: 'uint256' },
            ],
            name: 'buyerFundingRates',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                { internalType: 'address', name: '', type: 'address' },
                { internalType: 'uint256', name: '', type: 'uint256' },
            ],
            name: 'buyerPurchases',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                { internalType: 'address', name: '_addr', type: 'address' },
                {
                    internalType: 'uint256',
                    name: '_positionId',
                    type: 'uint256',
                },
            ],
            name: 'closePosition',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            name: 'feeQuotes',
            outputs: [
                {
                    internalType: 'uint256',
                    name: 'leverageFee',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: 'otmPriceFee',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: 'expirationTime',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [{ internalType: 'string', name: '', type: 'string' }],
            name: 'fundingRateHashToIndex',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            name: 'fundingRatePayments',
            outputs: [
                { internalType: 'address', name: 'buyer', type: 'address' },
                {
                    internalType: 'uint256',
                    name: 'perpetualId',
                    type: 'uint256',
                },
                { internalType: 'uint256', name: 'amount', type: 'uint256' },
                { internalType: 'uint256', name: 'timestamp', type: 'uint256' },
                {
                    internalType: 'enum RecordKeeper.FundingStatus',
                    name: 'status',
                    type: 'uint8',
                },
                { internalType: 'string', name: 'hash', type: 'string' },
                {
                    internalType: 'enum RecordKeeper.Direction',
                    name: 'direction',
                    type: 'uint8',
                },
                {
                    internalType: 'uint256',
                    name: 'positionId',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '_perpetualId',
                    type: 'uint256',
                },
                { internalType: 'uint256', name: '_amount', type: 'uint256' },
                { internalType: 'uint256', name: '_leverage', type: 'uint256' },
                { internalType: 'uint256', name: '_otmPrice', type: 'uint256' },
            ],
            name: 'generateFeeQuote',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [],
            name: 'getAllPerpetuals',
            outputs: [
                {
                    components: [
                        {
                            internalType: 'string',
                            name: 'name',
                            type: 'string',
                        },
                        {
                            internalType: 'string',
                            name: 'asset',
                            type: 'string',
                        },
                        {
                            internalType: 'uint256',
                            name: 'price',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address',
                            name: 'oracle',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'creationDate',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'shelfLife',
                            type: 'uint256',
                        },
                        { internalType: 'bool', name: 'status', type: 'bool' },
                        {
                            internalType: 'address',
                            name: 'perpetualAddress',
                            type: 'address',
                        },
                    ],
                    internalType: 'struct RecordKeeper.Perpetual[]',
                    name: '',
                    type: 'tuple[]',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'getAllPerpetualsWithStats',
            outputs: [
                {
                    components: [
                        {
                            components: [
                                {
                                    internalType: 'string',
                                    name: 'name',
                                    type: 'string',
                                },
                                {
                                    internalType: 'string',
                                    name: 'asset',
                                    type: 'string',
                                },
                                {
                                    internalType: 'uint256',
                                    name: 'price',
                                    type: 'uint256',
                                },
                                {
                                    internalType: 'address',
                                    name: 'oracle',
                                    type: 'address',
                                },
                                {
                                    internalType: 'uint256',
                                    name: 'creationDate',
                                    type: 'uint256',
                                },
                                {
                                    internalType: 'uint256',
                                    name: 'shelfLife',
                                    type: 'uint256',
                                },
                                {
                                    internalType: 'bool',
                                    name: 'status',
                                    type: 'bool',
                                },
                                {
                                    internalType: 'address',
                                    name: 'perpetualAddress',
                                    type: 'address',
                                },
                            ],
                            internalType: 'struct RecordKeeper.Perpetual',
                            name: 'perpetual',
                            type: 'tuple',
                        },
                        {
                            internalType: 'uint256',
                            name: 'percentageHigher',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'percentageLower',
                            type: 'uint256',
                        },
                    ],
                    internalType: 'struct RecordKeeper.PerpetualWithStats[]',
                    name: '',
                    type: 'tuple[]',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'getAllRecords',
            outputs: [
                {
                    components: [
                        {
                            internalType: 'address',
                            name: 'token',
                            type: 'address',
                        },
                        {
                            internalType: 'address',
                            name: 'addr',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amount',
                            type: 'uint256',
                        },
                        {
                            internalType: 'enum RecordKeeper.Direction',
                            name: 'direction',
                            type: 'uint8',
                        },
                        {
                            internalType: 'string',
                            name: 'transactionHash',
                            type: 'string',
                        },
                        {
                            internalType: 'uint256',
                            name: 'timestamp',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'unitCost',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'netQuantity',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'currentValuation',
                            type: 'uint256',
                        },
                    ],
                    internalType: 'struct RecordKeeper.Record[]',
                    name: '',
                    type: 'tuple[]',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                { internalType: 'address', name: '_token', type: 'address' },
                { internalType: 'address', name: '_addr', type: 'address' },
            ],
            name: 'getBalance',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                { internalType: 'address', name: '_addr', type: 'address' },
            ],
            name: 'getBalance',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [{ internalType: 'string', name: '_hash', type: 'string' }],
            name: 'getFundingRateByHash',
            outputs: [
                {
                    components: [
                        {
                            internalType: 'address',
                            name: 'buyer',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'perpetualId',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amount',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'timestamp',
                            type: 'uint256',
                        },
                        {
                            internalType: 'enum RecordKeeper.FundingStatus',
                            name: 'status',
                            type: 'uint8',
                        },
                        {
                            internalType: 'string',
                            name: 'hash',
                            type: 'string',
                        },
                        {
                            internalType: 'enum RecordKeeper.Direction',
                            name: 'direction',
                            type: 'uint8',
                        },
                        {
                            internalType: 'uint256',
                            name: 'positionId',
                            type: 'uint256',
                        },
                    ],
                    internalType: 'struct RecordKeeper.FundingRatePayment',
                    name: '',
                    type: 'tuple',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                { internalType: 'address', name: '_addr', type: 'address' },
            ],
            name: 'getFundingRatesByBuyer',
            outputs: [
                {
                    components: [
                        {
                            internalType: 'address',
                            name: 'buyer',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'perpetualId',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amount',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'timestamp',
                            type: 'uint256',
                        },
                        {
                            internalType: 'enum RecordKeeper.FundingStatus',
                            name: 'status',
                            type: 'uint8',
                        },
                        {
                            internalType: 'string',
                            name: 'hash',
                            type: 'string',
                        },
                        {
                            internalType: 'enum RecordKeeper.Direction',
                            name: 'direction',
                            type: 'uint8',
                        },
                        {
                            internalType: 'uint256',
                            name: 'positionId',
                            type: 'uint256',
                        },
                    ],
                    internalType: 'struct RecordKeeper.FundingRatePayment[]',
                    name: '',
                    type: 'tuple[]',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                { internalType: 'address', name: '_addr', type: 'address' },
                {
                    internalType: 'uint256',
                    name: '_positionId',
                    type: 'uint256',
                },
                { internalType: 'uint256', name: '_offset', type: 'uint256' },
                { internalType: 'uint256', name: '_limit', type: 'uint256' },
            ],
            name: 'getFundingRatesByPosition',
            outputs: [
                {
                    components: [
                        {
                            internalType: 'address',
                            name: 'buyer',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'perpetualId',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amount',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'timestamp',
                            type: 'uint256',
                        },
                        {
                            internalType: 'enum RecordKeeper.FundingStatus',
                            name: 'status',
                            type: 'uint8',
                        },
                        {
                            internalType: 'string',
                            name: 'hash',
                            type: 'string',
                        },
                        {
                            internalType: 'enum RecordKeeper.Direction',
                            name: 'direction',
                            type: 'uint8',
                        },
                        {
                            internalType: 'uint256',
                            name: 'positionId',
                            type: 'uint256',
                        },
                    ],
                    internalType: 'struct RecordKeeper.FundingRatePayment[]',
                    name: 'payments',
                    type: 'tuple[]',
                },
                { internalType: 'uint256', name: 'total', type: 'uint256' },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                { internalType: 'address', name: '_addr', type: 'address' },
            ],
            name: 'getNetBalance',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                { internalType: 'address', name: '_addr', type: 'address' },
            ],
            name: 'getOpenPositions',
            outputs: [
                {
                    components: [
                        {
                            internalType: 'uint256',
                            name: 'id',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'perpetualId',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amount',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'purchasePrice',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'purchaseTime',
                            type: 'uint256',
                        },
                        { internalType: 'bool', name: 'isLong', type: 'bool' },
                        {
                            internalType: 'bool',
                            name: 'isClosed',
                            type: 'bool',
                        },
                        {
                            internalType: 'uint256',
                            name: 'closedTime',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'accumulatedFundingRate',
                            type: 'uint256',
                        },
                    ],
                    internalType: 'struct RecordKeeper.Position[]',
                    name: '',
                    type: 'tuple[]',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                { internalType: 'address', name: '_addr', type: 'address' },
            ],
            name: 'getPurchasesByBuyer',
            outputs: [
                {
                    components: [
                        {
                            internalType: 'address',
                            name: 'buyer',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'perpetualId',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amount',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'purchaseTime',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'leverage',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'otmPrice',
                            type: 'uint256',
                        },
                        {
                            internalType: 'bytes32',
                            name: 'quoteId',
                            type: 'bytes32',
                        },
                        {
                            internalType: 'uint256',
                            name: 'positionIndex',
                            type: 'uint256',
                        },
                    ],
                    internalType: 'struct RecordKeeper.Purchase[]',
                    name: '',
                    type: 'tuple[]',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                { internalType: 'address', name: '_addr', type: 'address' },
                {
                    internalType: 'uint256',
                    name: '_positionId',
                    type: 'uint256',
                },
            ],
            name: 'getTotalFundingRateByPosition',
            outputs: [
                { internalType: 'uint256', name: 'totalPaid', type: 'uint256' },
                {
                    internalType: 'uint256',
                    name: 'totalReceived',
                    type: 'uint256',
                },
                { internalType: 'uint256', name: 'count', type: 'uint256' },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                { internalType: 'address', name: '_addr', type: 'address' },
            ],
            name: 'getTotalFundingRatePaidByBuyer',
            outputs: [
                { internalType: 'uint256', name: 'totalPaid', type: 'uint256' },
                {
                    internalType: 'uint256',
                    name: 'totalReceived',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'nextPositionId',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            name: 'perpetuals',
            outputs: [
                { internalType: 'string', name: 'name', type: 'string' },
                { internalType: 'string', name: 'asset', type: 'string' },
                { internalType: 'uint256', name: 'price', type: 'uint256' },
                { internalType: 'address', name: 'oracle', type: 'address' },
                {
                    internalType: 'uint256',
                    name: 'creationDate',
                    type: 'uint256',
                },
                { internalType: 'uint256', name: 'shelfLife', type: 'uint256' },
                { internalType: 'bool', name: 'status', type: 'bool' },
                {
                    internalType: 'address',
                    name: 'perpetualAddress',
                    type: 'address',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            name: 'purchases',
            outputs: [
                { internalType: 'address', name: 'buyer', type: 'address' },
                {
                    internalType: 'uint256',
                    name: 'perpetualId',
                    type: 'uint256',
                },
                { internalType: 'uint256', name: 'amount', type: 'uint256' },
                {
                    internalType: 'uint256',
                    name: 'purchaseTime',
                    type: 'uint256',
                },
                { internalType: 'uint256', name: 'leverage', type: 'uint256' },
                { internalType: 'uint256', name: 'otmPrice', type: 'uint256' },
                { internalType: 'bytes32', name: 'quoteId', type: 'bytes32' },
                {
                    internalType: 'uint256',
                    name: 'positionIndex',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                { internalType: 'address', name: '_addr', type: 'address' },
                {
                    components: [
                        {
                            internalType: 'uint256',
                            name: 'perpetualId',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amount',
                            type: 'uint256',
                        },
                        {
                            internalType: 'enum RecordKeeper.FundingStatus',
                            name: 'status',
                            type: 'uint8',
                        },
                        {
                            internalType: 'string',
                            name: 'hash',
                            type: 'string',
                        },
                        {
                            internalType: 'enum RecordKeeper.Direction',
                            name: 'direction',
                            type: 'uint8',
                        },
                        {
                            internalType: 'uint256',
                            name: 'positionId',
                            type: 'uint256',
                        },
                    ],
                    internalType:
                        'struct RecordKeeper.FundingRatePaymentData[]',
                    name: '_payments',
                    type: 'tuple[]',
                },
            ],
            name: 'recordFundingRatePayments',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                { internalType: 'address', name: '_addr', type: 'address' },
                {
                    internalType: 'uint256',
                    name: '_perpetualId',
                    type: 'uint256',
                },
                { internalType: 'uint256', name: '_amount', type: 'uint256' },
                {
                    internalType: 'enum RecordKeeper.FundingStatus',
                    name: '_status',
                    type: 'uint8',
                },
                { internalType: 'string', name: '_hash', type: 'string' },
                {
                    internalType: 'enum RecordKeeper.Direction',
                    name: '_direction',
                    type: 'uint8',
                },
                {
                    internalType: 'uint256',
                    name: '_positionId',
                    type: 'uint256',
                },
            ],
            name: 'recordSingleFundingRatePayment',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            name: 'records',
            outputs: [
                { internalType: 'address', name: 'token', type: 'address' },
                { internalType: 'address', name: 'addr', type: 'address' },
                { internalType: 'uint256', name: 'amount', type: 'uint256' },
                {
                    internalType: 'enum RecordKeeper.Direction',
                    name: 'direction',
                    type: 'uint8',
                },
                {
                    internalType: 'string',
                    name: 'transactionHash',
                    type: 'string',
                },
                { internalType: 'uint256', name: 'timestamp', type: 'uint256' },
                { internalType: 'uint256', name: 'unitCost', type: 'uint256' },
                {
                    internalType: 'uint256',
                    name: 'netQuantity',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: 'currentValuation',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                { internalType: 'address', name: '_addr', type: 'address' },
            ],
            name: 'removeApprovedAddress',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                { internalType: 'address', name: '_addr', type: 'address' },
                {
                    internalType: 'uint256',
                    name: '_positionId',
                    type: 'uint256',
                },
            ],
            name: 'searchRecordsByAddress',
            outputs: [
                {
                    components: [
                        {
                            components: [
                                {
                                    internalType: 'address',
                                    name: 'token',
                                    type: 'address',
                                },
                                {
                                    internalType: 'address',
                                    name: 'addr',
                                    type: 'address',
                                },
                                {
                                    internalType: 'uint256',
                                    name: 'amount',
                                    type: 'uint256',
                                },
                                {
                                    internalType: 'enum RecordKeeper.Direction',
                                    name: 'direction',
                                    type: 'uint8',
                                },
                                {
                                    internalType: 'string',
                                    name: 'transactionHash',
                                    type: 'string',
                                },
                                {
                                    internalType: 'uint256',
                                    name: 'timestamp',
                                    type: 'uint256',
                                },
                                {
                                    internalType: 'uint256',
                                    name: 'unitCost',
                                    type: 'uint256',
                                },
                                {
                                    internalType: 'uint256',
                                    name: 'netQuantity',
                                    type: 'uint256',
                                },
                                {
                                    internalType: 'uint256',
                                    name: 'currentValuation',
                                    type: 'uint256',
                                },
                            ],
                            internalType: 'struct RecordKeeper.Record',
                            name: 'record',
                            type: 'tuple',
                        },
                        {
                            internalType: 'uint256',
                            name: 'leverage',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'otmPrice',
                            type: 'uint256',
                        },
                        {
                            internalType: 'bytes32',
                            name: 'quoteId',
                            type: 'bytes32',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountInvested',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'currentValuation',
                            type: 'uint256',
                        },
                        { internalType: 'bool', name: 'isLong', type: 'bool' },
                        {
                            internalType: 'uint256',
                            name: 'positionId',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'leverageFee',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'otmPriceFee',
                            type: 'uint256',
                        },
                    ],
                    internalType: 'struct RecordKeeper.ExtendedRecord[]',
                    name: '',
                    type: 'tuple[]',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256[]',
                    name: '_prices',
                    type: 'uint256[]',
                },
            ],
            name: 'updateAllPrices',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                { internalType: 'string', name: '_hash', type: 'string' },
                {
                    internalType: 'enum RecordKeeper.FundingStatus',
                    name: '_status',
                    type: 'uint8',
                },
            ],
            name: 'updateFundingRatePaymentStatus',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                { internalType: 'uint256', name: '_id', type: 'uint256' },
                { internalType: 'string', name: '_name', type: 'string' },
                { internalType: 'string', name: '_asset', type: 'string' },
                { internalType: 'uint256', name: '_price', type: 'uint256' },
                { internalType: 'bool', name: '_status', type: 'bool' },
            ],
            name: 'updatePerpetual',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                { internalType: 'uint256', name: '_id', type: 'uint256' },
                { internalType: 'uint256', name: '_price', type: 'uint256' },
            ],
            name: 'updatePrice',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                { internalType: 'address', name: '', type: 'address' },
                { internalType: 'uint256', name: '', type: 'uint256' },
            ],
            name: 'userPositionIndex',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                { internalType: 'address', name: '', type: 'address' },
                { internalType: 'uint256', name: '', type: 'uint256' },
            ],
            name: 'userPositions',
            outputs: [
                { internalType: 'uint256', name: 'id', type: 'uint256' },
                {
                    internalType: 'uint256',
                    name: 'perpetualId',
                    type: 'uint256',
                },
                { internalType: 'uint256', name: 'amount', type: 'uint256' },
                {
                    internalType: 'uint256',
                    name: 'purchasePrice',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: 'purchaseTime',
                    type: 'uint256',
                },
                { internalType: 'bool', name: 'isLong', type: 'bool' },
                { internalType: 'bool', name: 'isClosed', type: 'bool' },
                {
                    internalType: 'uint256',
                    name: 'closedTime',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: 'accumulatedFundingRate',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
    ],

    chainlinkAbi: [
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_aggregator',
                    type: 'address',
                },
                {
                    internalType: 'address',
                    name: '_accessController',
                    type: 'address',
                },
            ],
            stateMutability: 'nonpayable',
            type: 'constructor',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'int256',
                    name: 'current',
                    type: 'int256',
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'roundId',
                    type: 'uint256',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'updatedAt',
                    type: 'uint256',
                },
            ],
            name: 'AnswerUpdated',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'roundId',
                    type: 'uint256',
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'startedBy',
                    type: 'address',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'startedAt',
                    type: 'uint256',
                },
            ],
            name: 'NewRound',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'from',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'to',
                    type: 'address',
                },
            ],
            name: 'OwnershipTransferRequested',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'from',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'to',
                    type: 'address',
                },
            ],
            name: 'OwnershipTransferred',
            type: 'event',
        },
        {
            inputs: [],
            name: 'acceptOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [],
            name: 'accessController',
            outputs: [
                {
                    internalType: 'contract AccessControllerInterface',
                    name: '',
                    type: 'address',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'aggregator',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_aggregator',
                    type: 'address',
                },
            ],
            name: 'confirmAggregator',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [],
            name: 'decimals',
            outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'description',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                { internalType: 'uint256', name: '_roundId', type: 'uint256' },
            ],
            name: 'getAnswer',
            outputs: [{ internalType: 'int256', name: '', type: 'int256' }],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                { internalType: 'uint80', name: '_roundId', type: 'uint80' },
            ],
            name: 'getRoundData',
            outputs: [
                { internalType: 'uint80', name: 'roundId', type: 'uint80' },
                { internalType: 'int256', name: 'answer', type: 'int256' },
                { internalType: 'uint256', name: 'startedAt', type: 'uint256' },
                { internalType: 'uint256', name: 'updatedAt', type: 'uint256' },
                {
                    internalType: 'uint80',
                    name: 'answeredInRound',
                    type: 'uint80',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                { internalType: 'uint256', name: '_roundId', type: 'uint256' },
            ],
            name: 'getTimestamp',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'latestAnswer',
            outputs: [{ internalType: 'int256', name: '', type: 'int256' }],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'latestRound',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'latestRoundData',
            outputs: [
                { internalType: 'uint80', name: 'roundId', type: 'uint80' },
                { internalType: 'int256', name: 'answer', type: 'int256' },
                { internalType: 'uint256', name: 'startedAt', type: 'uint256' },
                { internalType: 'uint256', name: 'updatedAt', type: 'uint256' },
                {
                    internalType: 'uint80',
                    name: 'answeredInRound',
                    type: 'uint80',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'latestTimestamp',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'owner',
            outputs: [
                { internalType: 'address payable', name: '', type: 'address' },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [{ internalType: 'uint16', name: '', type: 'uint16' }],
            name: 'phaseAggregators',
            outputs: [
                {
                    internalType: 'contract AggregatorV2V3Interface',
                    name: '',
                    type: 'address',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'phaseId',
            outputs: [{ internalType: 'uint16', name: '', type: 'uint16' }],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_aggregator',
                    type: 'address',
                },
            ],
            name: 'proposeAggregator',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [],
            name: 'proposedAggregator',
            outputs: [
                {
                    internalType: 'contract AggregatorV2V3Interface',
                    name: '',
                    type: 'address',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                { internalType: 'uint80', name: '_roundId', type: 'uint80' },
            ],
            name: 'proposedGetRoundData',
            outputs: [
                { internalType: 'uint80', name: 'roundId', type: 'uint80' },
                { internalType: 'int256', name: 'answer', type: 'int256' },
                { internalType: 'uint256', name: 'startedAt', type: 'uint256' },
                { internalType: 'uint256', name: 'updatedAt', type: 'uint256' },
                {
                    internalType: 'uint80',
                    name: 'answeredInRound',
                    type: 'uint80',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'proposedLatestRoundData',
            outputs: [
                { internalType: 'uint80', name: 'roundId', type: 'uint80' },
                { internalType: 'int256', name: 'answer', type: 'int256' },
                { internalType: 'uint256', name: 'startedAt', type: 'uint256' },
                { internalType: 'uint256', name: 'updatedAt', type: 'uint256' },
                {
                    internalType: 'uint80',
                    name: 'answeredInRound',
                    type: 'uint80',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_accessController',
                    type: 'address',
                },
            ],
            name: 'setController',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [{ internalType: 'address', name: '_to', type: 'address' }],
            name: 'transferOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [],
            name: 'version',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function',
        },
    ],
};

module.exports = config;
