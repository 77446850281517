import { useState, useEffect } from 'react';
import Web3 from 'web3';
import { useLocation, useNavigate } from 'react-router-dom';
import detectEthereumProvider from '@metamask/detect-provider';
import DepositModal from './DepositModal.js';
import BalanceModal from './BalanceModal.js';
import ReactTooltip from 'react-tooltip';
import ProgressBarModal from './ProgressBarModal.js';
const HDWalletProvider = require('@truffle/hdwallet-provider');

const config = require('./configs/tusd_abi.js');
const PORTFOLIO_ABI = config.portfolioAbi;

export default function OpenPositionDetailComponent() {
    const navigate = useNavigate();
    const [web3, setWeb3] = useState(null);
    const [perpetuals, setPerpetuals] = useState([]);
    const [records, setRecords] = useState([]);
    const [matchedPerpetual, setMatchedPerpetual] = useState(null);
    const [matchingRecord, setMatchingRecord] = useState(null);
    const [fundingBuyerPosition, setFundingBuyerPosition] = useState([]);
    const [metamaskAccount, setMetamaskAccount] = useState([]);
    const [contract, setContract] = useState(null);
    const [sidechainWeb3, setSidechainWeb3] = useState(null);
    const [sidechainAccounts, setSidechainAccounts] = useState([]);
    const [totalFundingRatePosition, setTotalFundingRatePosition] = useState(
        []
    );
    const location = useLocation();
    const [progress, setProgress] = useState(0);
    const [transactionLink, setTransactionLink] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [portfolioAddress, setPortfolioAddress] = useState(null);
    const [privateKeys, setPrivateKeys] = useState(null);
    const [providerOrUrl, setProviderOrUrl] = useState(null);
    const entriesPerPage = 10;
    const { position, index } = location.state || {};

    const navigateBack = () => {
        navigate(-1); // Takes the user to the previous page
    };

    const serverURL = process.env.REACT_APP_SERVER_URL;

    useEffect(() => {
        const fetchSecrets = async () => {
            try {
                const response = await fetch(`${serverURL}/api/secrets`);
                const data = await response.json();

                setPortfolioAddress(data.portfolioAddress);
                setProviderOrUrl(data.providerOrUrl);

                let formattedPrivateKeys = data.privateKeys;

                if (typeof formattedPrivateKeys === 'string') {
                    try {
                        formattedPrivateKeys = JSON.parse(formattedPrivateKeys);
                    } catch (e) {
                        formattedPrivateKeys = [formattedPrivateKeys];
                    }
                }

                formattedPrivateKeys = formattedPrivateKeys.map((key) =>
                    key.startsWith('0x') ? key : `0x${key}`
                );

                setPrivateKeys(formattedPrivateKeys);
            } catch (error) {
                console.error('Error fetching secrets:', error);
            }
        };

        fetchSecrets();
    }, []);

    useEffect(() => {
        if (!privateKeys || !providerOrUrl || privateKeys.length === 0) {
            return;
        }

        let provider;
        let positionId = position.id;
        async function fetchWeb3Data() {
            try {
                const token = localStorage.getItem('jwtToken');
                if (!token) {
                    window.location.href = '/sign';
                    return;
                }
                provider = await detectEthereumProvider();
                if (!provider) {
                    console.error('No Ethereum provider detected.');
                    return;
                }

                await provider.request({ method: 'eth_requestAccounts' });

                const web3Instance = new Web3(provider);
                setWeb3(web3Instance);

                const metamaskAccounts = await web3Instance.eth.getAccounts();
                setMetamaskAccount(metamaskAccounts);

                const response = await fetch(
                    `${serverURL}/api/init-openDetail`,
                    {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            privateKeys,
                            providerOrUrl,
                            metamaskAccounts,
                            positionId,
                        }),
                    }
                );

                if (!response.ok) {
                    throw new Error('Failed to fetch Web3 data, throw');
                }

                const data = await response.json();
                setSidechainAccounts(data.accounts);

                const sidechainWeb3Instance = new Web3(providerOrUrl);
                setSidechainWeb3(sidechainWeb3Instance);

                const contractInstance = new sidechainWeb3Instance.eth.Contract(
                    PORTFOLIO_ABI,
                    portfolioAddress
                );

                setContract(contractInstance);
                console.log('data', data);
                if (contractInstance && metamaskAccounts.length > 0) {
                    setPerpetuals(data.perpetualsWithStats);
                    setRecords(data.userRecords);
                    setFundingBuyerPosition(data.fundingBuyerPosition);
                    setTotalFundingRatePosition(data.totalFundingRatePosition);
                }
            } catch (error) {
                console.error('Error fetching Web3 data:', error);
            }
        }

        fetchWeb3Data();
    }, [privateKeys, providerOrUrl]);

    useEffect(() => {
        if (position && index !== undefined && contract) {
            fetchPerpetuals();
            fetchPositionDetail();
        }
    }, [position, index, contract]);

    useEffect(() => {
        // console.log('matching record', matchingRecord);
        if (matchingRecord) {
            //fetchFundingBuyer();
        }
    }, [matchingRecord]);

    const fetchPerpetuals = async () => {
        try {
            const matchedPerpetual = perpetuals[index];
            console.log('perp', perpetuals);
            console.log('index', index);
            console.log('match perp', matchedPerpetual);
            setMatchedPerpetual(matchedPerpetual);
        } catch (error) {
            console.error('Error fetching perpetuals:', error);
        }
    };

    const fetchPositionDetail = async () => {
        try {
            console.log('records', records);
            console.log('positions', position);

            const foundRecord = records.find(
                (record) => record?.positionId === position.id
            );
            if (foundRecord) {
                console.log('matching record', foundRecord);
                setMatchingRecord(foundRecord);
            }
        } catch (error) {
            console.error('Error fetching position detail:', error);
        }
    };

    const fetchFundingBuyer = async () => {
        try {
            if (!matchingRecord) {
                console.error('No matching record found');
                return;
            }
            const entries = fundingEntries
                .filter(
                    (record) => record?.positionId === matchingRecord.positionId
                )
                .map((record) => ({
                    fundingTime: new Date(
                        record.timestamp * 1000
                    ).toLocaleString('en-US', {
                        month: '2-digit',
                        day: '2-digit',
                        year: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: false,
                    }),
                    fundingPrice: record.amount,
                    direction: record.direction,
                }));

            setFundingEntries(entries);
        } catch (error) {
            console.error('Error fetching funding details:', error);
        }
    };

    const handleClosePerpetual = async () => {
        const userAddy = metamaskAccount[0];
        setIsModalOpen(true);
        setProgress(30);
        console.log(
            'user addy and position Id',
            userAddy,
            matchingRecord.positionId
        );
        try {
            const response = await fetch(`${serverURL}/api/closePosition`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    address: userAddy,
                    positionId: matchingRecord.positionId,
                }),
            }).catch((error) => console.error('Fetch error:', error));

            const data = await response.json();
            console.log('data', data);
            const transactionHash = data.transactionHash;
            console.log('transactionHash', transactionHash);
            if (transactionHash) {
                const link = `https://blocks.onlyx.io/tx/${transactionHash}`;
                setTransactionLink(link);
                setProgress(100);
            }
            navigate(-1);
        } catch (error) {
            console.log('error', error);
        }
    };

    return (
        <div className="w-full lg:w-[1290px] h-auto lg:h-[734px] relative mt-8 lg:mt-[63px] ml-4 lg:ml-[111px]">
            <div className="flex w-full lg:w-[230px] h-auto lg:h-[656px] flex-col gap-4 lg:gap-[18px] top-0 left-0">
                <div className="flex items-center gap-2">
                    <div className="w-4 h-3 bg-cover bg-no-repeat back-icon"></div>
                    <button
                        className="text-gray-600 text-sm left-5 lg:left-20"
                        onClick={navigateBack}
                    >
                        Back
                    </button>
                </div>

                {/* Deposit Section */}
                <BalanceModal />
                <DepositModal />
            </div>

            <div className="flex flex-col gap-2 items-center w-full lg:w-[1040px] h-auto lg:h-[800px] p-2 bg-white rounded-lg border border-blue-200 absolute top-9 left-[250px] ">
                <div className="flex flex-col gap-2 bg-gray-100 rounded-md p-4 w-full">
                    <div className="flex gap-2 items-center">
                        <div className="w-4 h-3 bg-cover bg-no-repeat market-icon"></div>
                        <span className="text-lg lg:text-2xl font-bold text-blue-800">
                            Open Detail
                        </span>
                    </div>
                    <span className="text-xs text-gray-500">
                        Shows details of open position
                    </span>
                </div>

                {/* Position Details Content */}
                {matchedPerpetual && matchingRecord ? (
                    <div className="flex pt-0 pr-6 pb-0 pl-6 flex-col gap-3 items-center self-stretch shrink-0 flex-nowrap relative ">
                        <div className="flex pt-2.5 pr-0 pb-2.5 pl-0 flex-col gap-4 items-start self-stretch shrink-0 flex-nowrap relative ">
                            <div className="flex w-full md:w-4/5 lg:w-full items-start shrink-0 flex-nowrap relative ">
                                {/* Asset Section */}
                                <div className="flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative ">
                                    <span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
                                        Asset&nbsp;
                                        <button
                                            data-tip="Peretual Asset"
                                            id="myButton"
                                            className="tooltip-icon"
                                        ></button>
                                        <ReactTooltip anchorId="myButton" />
                                    </span>
                                    <span className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-normal text-blue-800 relative text-left whitespace-nowrap ">
                                        {matchedPerpetual.asset}
                                    </span>
                                </div>
                                {/* Name Section */}
                                <div className="flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative ">
                                    <span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
                                        Name&nbsp;
                                        <button
                                            data-tip="Name of Perpetual"
                                            id="myButton"
                                            className="tooltip-icon"
                                        ></button>
                                        <ReactTooltip anchorId="myButton" />
                                    </span>
                                    <span className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-5 text-blue-800 relative text-left whitespace-nowrap ">
                                        {matchedPerpetual.name}
                                    </span>
                                </div>
                                {/* Contract Address Section */}
                                <div className="flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative ">
                                    <span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
                                        Contract Address&nbsp;
                                        <button
                                            data-tip="Contract Address of Perpetual"
                                            id="myButton"
                                            className="tooltip-icon"
                                        ></button>
                                        <ReactTooltip anchorId="myButton" />
                                    </span>
                                    <span className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-medium leading-5 text-blue-800 relative text-left whitespace-nowrap ">
                                        {`${matchedPerpetual.perpetualAddress.slice(
                                            0,
                                            6
                                        )}...${matchedPerpetual.perpetualAddress.slice(
                                            -4
                                        )}`}
                                        {/* {`${matchedPerpetual.perpetual.perpetualAddress}`} */}
                                    </span>
                                </div>
                                <div className="flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative ">
                                    <span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
                                        Open Date&nbsp;
                                        <button
                                            data-tip="Date of Perpetual Position Opened "
                                            id="myButton"
                                            className="tooltip-icon"
                                        ></button>
                                        <ReactTooltip anchorId="myButton" />
                                    </span>
                                    <span className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-normal text-blue-800 relative text-left whitespace-nowrap ">
                                        {position.purchaseTime}
                                    </span>
                                </div>
                            </div>
                        </div>

                        {/* the line goes here */}
                        <div className="h-px self-stretch shrink-0 bg-cover bg-no-repeat relative " />

                        <div className="flex pt-2.5 pr-0 pb-2.5 pl-0 flex-col gap-4 items-start self-stretch shrink-0 flex-nowrap relative ">
                            <div className="flex w-full md:w-4/5 lg:w-full items-start shrink-0 flex-nowrap relative ">
                                {/* Date Section */}

                                {/* Current Price Section */}
                                <div className="flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative ">
                                    <span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
                                        Position&nbsp;
                                        <button
                                            data-tip="Type of Position Opened"
                                            id="myButton"
                                            className="tooltip-icon"
                                        ></button>
                                        <ReactTooltip anchorId="myButton" />
                                    </span>
                                    <span
                                        className={`h-5 shrink-0 font-['Inter'] text-base md:text-lg font-normal relative text-left whitespace-nowrap ${
                                            position.isLong
                                                ? 'text-[#10b981]'
                                                : 'text-[#1e3a8a]'
                                        }`}
                                    >
                                        <span
                                            className={`${
                                                position.isLong
                                                    ? 'long-icon'
                                                    : 'short-icon'
                                            } inline-block mr-1`}
                                            aria-hidden="true"
                                        ></span>
                                        {position.isLong ? 'Long' : 'Short'}
                                    </span>
                                </div>
                                <div className="flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative ">
                                    <span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
                                        Leverage&nbsp;
                                        <button
                                            data-tip="Leverage of Position"
                                            id="myButton"
                                            className="tooltip-icon"
                                        ></button>
                                        <ReactTooltip anchorId="myButton" />
                                    </span>
                                    <span className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-5 text-blue-800 relative text-left whitespace-nowrap ">
                                        {matchingRecord.leverage}x
                                    </span>
                                </div>
                                <div className="flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative ">
                                    <span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
                                        Current Value (tUSD)&nbsp;
                                        <button
                                            data-tip="Current Value of Position"
                                            id="myButton"
                                            className="tooltip-icon"
                                        ></button>
                                        <ReactTooltip anchorId="myButton" />
                                    </span>
                                    <span className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-medium leading-5 text-blue-800 relative text-left whitespace-nowrap ">
                                        ${matchingRecord.currentValuation}
                                    </span>
                                </div>
                                <div className="flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative ">
                                    <span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
                                        Profit/Loss (tUSD)&nbsp;
                                        <button
                                            data-tip="Profit or Loss of Position, Calculated by Purchased Power minus Current Value"
                                            id="myButton"
                                            className="tooltip-icon"
                                        ></button>
                                        <ReactTooltip
                                            anchorId="myButton"
                                            place="top"
                                            effect="solid"
                                        />
                                    </span>
                                    <span className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-medium leading-5 text-blue-800 relative text-left whitespace-nowrap ">
                                        {(() => {
                                            const investedWei = parseFloat(
                                                matchingRecord.amountInvested.replace(
                                                    /,/g,
                                                    ''
                                                )
                                            );

                                            const valuationWei = parseFloat(
                                                matchingRecord.currentValuation.replace(
                                                    /,/g,
                                                    ''
                                                )
                                            );

                                            const differenceWei =
                                                valuationWei - investedWei;

                                            const textColor =
                                                differenceWei >= 0
                                                    ? 'text-green-500'
                                                    : 'text-red-500';

                                            return (
                                                <span
                                                    className={`h-5 shrink-0 font-['Inter'] text-base md:text-lg font-medium leading-5 ${textColor} relative text-left whitespace-nowrap`}
                                                >
                                                    $
                                                    {differenceWei.toLocaleString(
                                                        undefined,
                                                        {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        }
                                                    )}
                                                </span>
                                            );
                                        })()}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="flex pt-2.5 pr-0 pb-2.5 pl-0 flex-col gap-4 items-start self-stretch shrink-0 flex-nowrap relative ">
                            <div className="flex w-full md:w-4/5 lg:w-full items-start shrink-0 flex-nowrap relative ">
                                {/* Position Section */}

                                <div className="flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative ">
                                    <span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
                                        Current Price (tUSD)&nbsp;
                                        <button
                                            data-tip="Current Price of Perpetual"
                                            id="myButton"
                                            className="tooltip-icon"
                                        ></button>
                                        <ReactTooltip anchorId="myButton" />
                                    </span>
                                    <span className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-5 text-blue-800 relative text-left whitespace-nowrap ">
                                        ${matchedPerpetual.price.toString()}
                                    </span>
                                </div>
                                <div className="flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative ">
                                    <span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
                                        Asset Price at Purchase&nbsp;
                                        <button
                                            data-tip="Price of Perpetual at Purchased"
                                            id="myButton"
                                            className="tooltip-icon"
                                        ></button>
                                        <ReactTooltip anchorId="myButton" />
                                    </span>
                                    <span className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-medium leading-5 text-blue-800 relative text-left whitespace-nowrap ">
                                        ${matchingRecord.unitCost}
                                        {/* ${parseFloat(matchingRecord.otmPriceFee.toString()).toFixed(2)} */}
                                    </span>
                                </div>
                                {/* Leverage Section */}

                                {/* OTM Price Section */}
                                <div className="flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative ">
                                    <span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
                                        Purchased Power (tUSD)&nbsp;
                                        <button
                                            data-tip="Purchased Power at time of position opened, Purchased Amount times the Leverage"
                                            id="myButton"
                                            className="tooltip-icon"
                                        ></button>
                                        <ReactTooltip anchorId="myButton" />
                                    </span>
                                    <span className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-medium leading-5 text-blue-800 relative text-left whitespace-nowrap ">
                                        ${matchingRecord.amountInvested}
                                        {/* ${parseFloat(matchingRecord.amountInvested.toString()).toFixed(2)} */}
                                    </span>
                                </div>
                                <div className="flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative ">
                                    <span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
                                        OTM Perpetual Price (tUSD)&nbsp;
                                        <button
                                            data-tip="Out of the Money Perpetual Price"
                                            id="myButton"
                                            className="tooltip-icon"
                                        ></button>
                                        <ReactTooltip anchorId="myButton" />
                                    </span>
                                    <span className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-medium leading-5 text-blue-800 relative text-left whitespace-nowrap ">
                                        ${matchingRecord.otmPrice}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="flex pt-2.5 pr-0 pb-2.5 pl-0 flex-col gap-4 items-start self-stretch shrink-0 flex-nowrap relative ">
                            <div className="flex w-full md:w-4/5 lg:w-full items-start shrink-0 flex-nowrap relative ">
                                {/* Market Price Section */}
                                <div className="flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative ">
                                    <span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
                                        Purchased Amount (tUSD)&nbsp;
                                        <button
                                            data-tip="Total Purchased Amount"
                                            id="myButton"
                                            className="tooltip-icon"
                                        ></button>
                                        <ReactTooltip anchorId="myButton" />
                                    </span>
                                    <span className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-medium leading-5 text-blue-800 relative text-left whitespace-nowrap ">
                                        ${position.amount}
                                    </span>
                                </div>
                                <div className="flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative ">
                                    <span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
                                        Leverage Fee (tUSD)&nbsp;
                                        <button
                                            data-tip="Leverage at Time of Purchase"
                                            id="myButton"
                                            className="tooltip-icon"
                                        ></button>
                                        <ReactTooltip anchorId="myButton" />
                                    </span>
                                    <span className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-5 text-blue-800 relative text-left whitespace-nowrap ">
                                        ${matchingRecord.leverageFee}
                                    </span>
                                </div>
                                <div className="flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative ">
                                    <span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
                                        OTM Fee (tUSD)&nbsp;
                                        <button
                                            data-tip="Out of the Money Fee"
                                            id="myButton"
                                            className="tooltip-icon"
                                        ></button>
                                        <ReactTooltip anchorId="myButton" />
                                    </span>
                                    <span className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-medium leading-5 text-blue-800 relative text-left whitespace-nowrap ">
                                        ${matchingRecord.otmPriceFee}
                                    </span>
                                </div>

                                <div className="flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative ">
                                    <span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap ">
                                        Total Amount Purchased&nbsp;
                                        <button
                                            data-tip="Total Amount Purchased. Purchased Amount plus Leverage Fee plus OTM Fee"
                                            id="myButton"
                                            className="tooltip-icon"
                                        ></button>
                                        <ReactTooltip anchorId="myButton" />
                                    </span>
                                    <span className="h-5 shrink-0 font-['Inter'] text-base md:text-lg font-medium leading-5 text-blue-800 relative text-left whitespace-nowrap ">
                                        ${matchingRecord.amount}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="flex pt-2.5 pr-0 pb-2.5 pl-0 flex-col gap-4 items-start self-stretch shrink-0 flex-nowrap relative">
                            <div className="flex w-full md:w-4/5 lg:w-full items-start shrink-0 flex-nowrap relative">
                                {/* Funding Interval Column */}
                                <div className="flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative">
                                    <span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap">
                                        Funding Date/Time&nbsp;
                                        <button
                                            data-tip="Please Note Only 5 Most Recent Will Display"
                                            id="myButton"
                                            className="tooltip-icon"
                                        ></button>
                                        <ReactTooltip anchorId="myButton" />
                                    </span>
                                    {fundingBuyerPosition.position.map(
                                        (pos, index) => (
                                            <span
                                                key={index}
                                                className="text-gray-700"
                                            >
                                                {pos.timestamp}
                                            </span>
                                        )
                                    )}
                                </div>

                                {/* Funded Price Column */}
                                <div className="flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative">
                                    <span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap">
                                        Funded Price&nbsp;
                                        <button
                                            data-tip="Margin Call"
                                            id="myButton"
                                            className="tooltip-icon"
                                        ></button>
                                        <ReactTooltip anchorId="myButton" />
                                    </span>
                                    {fundingBuyerPosition.position.map(
                                        (pos, index) => (
                                            <span
                                                key={index}
                                                className="text-gray-700"
                                            >
                                                ${pos.amount}
                                            </span>
                                        )
                                    )}
                                </div>
                                <div className="flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative">
                                    <span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap">
                                        Funded Status&nbsp;
                                        <button
                                            data-tip=""
                                            id="myButton"
                                            className="tooltip-icon"
                                        ></button>
                                        <ReactTooltip anchorId="myButton" />
                                    </span>
                                    {fundingBuyerPosition.position.map(
                                        (pos, index) => {
                                            // Map status values to their corresponding text
                                            const statusText = {
                                                0: 'Paid',
                                                1: 'Recieved',
                                            };

                                            return (
                                                <span
                                                    key={index}
                                                    className="text-gray-700"
                                                >
                                                    {statusText[
                                                        pos.direction
                                                    ] ?? 'Unknown Status'}
                                                </span>
                                            );
                                        }
                                    )}
                                </div>
                                {/* <div className="flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative">
                                    <span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap">
                                        Funded Status&nbsp;
                                        <button
                                            data-tip=""
                                            id="myButton"
                                            className="tooltip-icon"
                                        ></button>
                                        <ReactTooltip anchorId="myButton" />
                                    </span>
                                    {fundingBuyerPosition.position.map(
                                        (pos, index) => {
                                            // Map status values to their corresponding text
                                            const statusText = {
                                                0: 'Not Paid Out',
                                                1: 'In Progress',
                                                2: 'Successful',
                                                3: 'Failure',
                                            };

                                            return (
                                                <span
                                                    key={index}
                                                    className="text-gray-700"
                                                >
                                                    {statusText[pos.status] ??
                                                        'Unknown Status'}
                                                </span>
                                            );
                                        }
                                    )}
                                </div> */}

                                {/* Accumulated Funded Price Column */}
                                <div className="flex flex-1 flex-col gap-1 justify-center items-start shrink-0 relative">
                                    <span className="h-4 shrink-0 font-['Inter'] text-base md:text-lg font-normal leading-4 text-gray-500 relative text-left whitespace-nowrap">
                                        Accumulated Funded Price&nbsp;
                                        <button
                                            data-tip="Accumulated Margin Call"
                                            id="myButton"
                                            className="tooltip-icon"
                                        ></button>
                                        <ReactTooltip anchorId="myButton" />
                                    </span>
                                    {totalFundingRatePosition.length > 0 ? (
                                        (() => {
                                            const totalFundingPrice =
                                                totalFundingRatePosition[0]
                                                    .totalDiff;

                                            const textColor =
                                                totalFundingPrice >= 0
                                                    ? 'text-green-500'
                                                    : 'text-red-500';

                                            return (
                                                <span
                                                    className={`h-5 shrink-0 font-['Inter'] text-base md:text-lg font-medium leading-5 ${textColor} relative text-left whitespace-nowrap`}
                                                >
                                                    {`$${totalFundingPrice}`}
                                                </span>
                                            );
                                        })()
                                    ) : (
                                        <span>Loading funding details...</span>
                                    )}
                                </div>
                            </div>

                            {/* Pagination Controls */}
                            <div className="flex w-full justify-between mt-4">
                                <button
                                    className="w-[197px] h-[44px] bg-[#ef4444] text-white rounded-[24px] flex justify-center items-center"
                                    onClick={handleClosePerpetual}
                                >
                                    Close
                                </button>
                                <ProgressBarModal
                                    isOpen={isModalOpen}
                                    onRequestClose={() => setIsModalOpen(false)}
                                    progress={progress}
                                    transactionLink={transactionLink}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <p>Loading position details...</p>
                )}
            </div>
        </div>
    );
}
