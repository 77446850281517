import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';

import ProgressBarModal from './ProgressBarModal.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactTooltip from 'react-tooltip';
const HDWalletProvider = require('@truffle/hdwallet-provider');
const config = require('./configs/tusd_abi.js');
const PORTFOLIO_ABI = config.portfolioAbi;

export default function BalanceModal() {
    const [amountUSD, setAmountUSD] = useState('');
    const [balance, setBalance] = useState(0);
    const [networth, setNetworth] = useState(0);
    const [profitLoss, setProfitLoss] = useState(0);
    const [web3, setWeb3] = useState(null);
    const [sidechainWeb3, setSidechainWeb3] = useState(null);
    const [sidechainAccounts, setSidechainAccounts] = useState([]);
    const [metamaskAccount, setMetamaskAccount] = useState([]);
    const [contract, setContract] = useState(null);
    const [progress, setProgress] = useState(0);
    const [transactionLink, setTransactionLink] = useState(null);
    const [totalPositionAmount, setTotalPositionAmount] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [portfolioAddress, setPortfolioAddress] = useState(null);
    const [privateKeys, setPrivateKeys] = useState(null);
    const [providerOrUrl, setProviderOrUrl] = useState(null);

    const serverURL = process.env.REACT_APP_SERVER_URL;

    useEffect(() => {
        const fetchSecrets = async () => {
            try {
                const response = await fetch(`${serverURL}/api/secrets`);
                const data = await response.json();

                setPortfolioAddress(data.portfolioAddress);
                setProviderOrUrl(data.providerOrUrl);

                let formattedPrivateKeys = data.privateKeys;

                if (typeof formattedPrivateKeys === 'string') {
                    try {
                        formattedPrivateKeys = JSON.parse(formattedPrivateKeys);
                    } catch (e) {
                        formattedPrivateKeys = [formattedPrivateKeys];
                    }
                }
                formattedPrivateKeys = formattedPrivateKeys.map((key) =>
                    key.startsWith('0x') ? key : `0x${key}`
                );

                setPrivateKeys(formattedPrivateKeys);
            } catch (error) {
                console.error('Error fetching secrets:', error);
            }
        };

        fetchSecrets();
    }, []);

    useEffect(() => {
        if (!privateKeys || !providerOrUrl || privateKeys.length === 0) {
            return;
        }

        let provider;
        async function fetchWeb3Data() {
            try {
                const token = localStorage.getItem('jwtToken');
                if (!token) {
                    window.location.href = '/sign';
                    return;
                }
                provider = await detectEthereumProvider();
                if (!provider) {
                    console.error('No Ethereum provider detected.');
                    return;
                }

                await provider.request({ method: 'eth_requestAccounts' });

                const web3Instance = new Web3(provider);
                setWeb3(web3Instance);

                const metamaskAccounts = await web3Instance.eth.getAccounts();
                setMetamaskAccount(metamaskAccounts);

                const response = await fetch(`${serverURL}/api/init-balance`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        privateKeys,
                        providerOrUrl,
                        metamaskAccounts,
                    }),
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch Web3 data');
                }

                const data = await response.json();
                setSidechainAccounts(data.accounts);
                const sidechainWeb3Instance = new Web3(providerOrUrl);
                setSidechainWeb3(sidechainWeb3Instance);

                const contractInstance = new sidechainWeb3Instance.eth.Contract(
                    PORTFOLIO_ABI,
                    portfolioAddress
                );

                setContract(contractInstance);

                if (contractInstance && metamaskAccounts.length > 0) {
                    setBalance(data.balance);
                    setNetworth(data.networth);
                    setProfitLoss(data.profitLoss);
                    setTotalPositionAmount(data.openPositionsTotal);
                    console.log('data', data);
                }
            } catch (error) {
                console.error('Error fetching Web3 data:', error);
            }
        }

        fetchWeb3Data();
    }, [privateKeys, providerOrUrl]);

    const withdrawAssets = async () => {
        if (!web3) {
            console.log(
                'Web3 is not initialized. Click "Connect Wallet" first.'
            );
            return;
        }

        if (metamaskAccount.length === 0) {
            console.error(
                'No accounts found. Make sure MetaMask is connected.'
            );
            return;
        }
        if (isNaN(amountUSD) || amountUSD <= 0) {
            console.error('Invalid amount. Please enter a valid number.');
            return;
        }
        const recipientAddress = metamaskAccount[0];
        const price = window.tusdPrice;
        const amountInTokens = amountUSD / price;

        try {
            handleWithdraw(recipientAddress, amountInTokens);
        } catch (error) {
            console.error('Error during withdrawal transaction:', error);
        }
    };

    const handleWithdraw = async (recipient, amount) => {
        if (!recipient || !amount) return;
        if (100 > balance - amount) {
            toast.error(
                'The withdraw amount is over the minimum amount of $100 needed to be in account balance'
            );
        } else {
            setIsModalOpen(true);
            try {
                const withdrawResponse = await fetch(
                    `${serverURL}/api/contract/withdraw`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            address: recipient,
                            amount: amount,
                        }),
                    }
                );

                if (!withdrawResponse.ok) {
                    throw new Error('Error withdrawing');
                }

                const data = await withdrawResponse.json();

                const transactionHash = data.hash;
                if (transactionHash) {
                    const link = `https://blocks.onlyx.io/tx/${transactionHash}`;
                    setTransactionLink(link);
                    setProgress(30);
                    toast.info('Transaction pending...');

                    const checkReceipt = async () => {
                        try {
                            const receipt =
                                await web3.eth.getTransactionReceipt(
                                    transactionHash
                                );
                            if (receipt) {
                                setProgress(50);

                                fetch(`${serverURL}/api/contract/add_record`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                    body: JSON.stringify({
                                        token: recipient,
                                        address: recipient,
                                        amount: amount,
                                        direction: '1',
                                        transactionHash:
                                            receipt.transactionHash,
                                    }),
                                })
                                    .then(() => {
                                        setProgress(100);
                                        toast.success('Transaction confirmed!');
                                        setIsModalOpen(false);
                                    })
                                    .catch((error) => {
                                        console.error(
                                            'Failed to add record to the server:',
                                            error
                                        );
                                        setProgress(0);
                                        setIsModalOpen(false);
                                    });
                            } else {
                                setTimeout(checkReceipt, 2000);
                            }
                        } catch (error) {
                            console.error('Error getting receipt:', error);
                        }
                    };
                    checkReceipt();
                } else {
                    throw new Error('Transaction hash not found.');
                }
            } catch (error) {
                console.error('Error during withdrawal:', error);
            }
        }
    };

    return (
        <div className="flex flex-col gap-[12px] bg-white rounded-2xl border border-blue-200 p-1 md:p-1 lg:p-1">
            <div className="flex justify-center items-center gap-2 w-full h-[52px] bg-gray-100 rounded-2xl">
                <div className="balance-icon" />
                <span className="font-bold text-gray-500 text-xl md:text-2xl">
                    My Balance
                </span>
            </div>

            <div className="flex flex-col w-full px-[1px] md:px-[4px] lg:px-[6px] gap-[12px]">
                <div className="flex flex-col gap-[4px]">
                    <span className="text-[12px] md:text-[12px] text-[#6b7280]">
                        Account Balance (tUSD)&nbsp;
                        <button
                            data-tip="Cash or Cash Equilavent"
                            id="myButton"
                            className="tooltip-icon"
                        ></button>
                        <ReactTooltip anchorId="myButton" />
                    </span>
                    <span className="text-[10px] md:text-[12px] font-bold text-[#9ca3af]">
                        ${balance}
                    </span>
                </div>
                <div className="flex flex-col gap-[4px]">
                    <span className="text-[10px] md:text-[12px] text-[#6b7280]">
                        Open Positions (tUSD)&nbsp;
                        <button
                            data-tip="Current Open Positions"
                            id="myButton"
                            className="tooltip-icon"
                        ></button>
                        <ReactTooltip anchorId="myButton" />
                    </span>
                    <span className="text-[10px] md:text-[12px] font-bold text-[#fb923c]">
                        ${totalPositionAmount}
                        {/* ${parseFloat(Web3.utils.fromWei(currentValuation.toString(), 'ether')).toFixed(2)} */}
                    </span>
                </div>
                <div className="flex flex-col gap-[4px]">
                    <span className="text-[10px] md:text-[12px] text-[#6b7280]">
                        Networth (tUSD)&nbsp;
                        <button
                            data-tip="Total Networth"
                            id="myButton"
                            className="tooltip-icon"
                        ></button>
                        <ReactTooltip anchorId="myButton" />
                    </span>
                    <span className="text-[10px] md:text-[12px] font-bold text-[#1e3a8a]">
                        ${networth}
                    </span>
                </div>

                {/* Total Unrealized PnL */}
                <div className="flex flex-col gap-[4px]">
                    <span className="text-[10px] md:text-[12px] text-[#6b7280]">
                        Total Unrealized PnL (tUSD)&nbsp;
                        <button
                            data-tip="Unrealized Profit or Loss"
                            id="myButton"
                            className="tooltip-icon"
                        ></button>
                        <ReactTooltip anchorId="myButton" />
                    </span>
                    <div className="flex gap-[6px] items-center">
                        <span
                            className={
                                parseFloat(profitLoss) >= 0
                                    ? 'profit-icon'
                                    : 'loss-icon'
                            }
                        />
                        <span
                            className={`text-[16px] md:text-[18px] font-bold ${
                                parseFloat(profitLoss) >= 0
                                    ? 'text-[#10b981]'
                                    : 'text-[#ef4444]'
                            }`}
                        >
                            {/* ${Number(profitLoss).toLocaleString()} */}$
                            {profitLoss}
                        </span>
                    </div>
                </div>

                <div className="flex flex-col gap-1 items-start w-full">
                    <span className="text-xs md:text-sm text-gray-500">
                        Enter Withdraw Amount (tUSD)
                    </span>

                    <div className="flex items-center gap-2 w-full border border-gray-500 rounded-md p-2">
                        <input
                            type="number"
                            id="amount"
                            value={amountUSD}
                            onChange={(e) => setAmountUSD(e.target.value)}
                            placeholder="Amount"
                            className="w-full text-sm text-base "
                        />
                    </div>
                </div>
                <button
                    className="flex items-center justify-center gap-2 w-full h-[52px] bg-white rounded-2xl border border-gray-500 mt-4 hover:bg-gray-100"
                    onClick={withdrawAssets}
                >
                    <span className="font-bold text-gray-500 text-sm md:text-base">
                        Withdraw
                    </span>
                    <span className="deposit-icon" />
                </button>
                <ProgressBarModal
                    isOpen={isModalOpen}
                    onRequestClose={() => setIsModalOpen(false)}
                    progress={progress}
                    transactionLink={transactionLink}
                />
            </div>
        </div>
    );
}
